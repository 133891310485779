// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { CardSection } from '../../layout';
import { ContactMethod, EditModal } from './components';
// Types
import { IContactAddress } from '@trii/types/dist/Contacts';
import { Box as MuiBox } from '@mui/material';
import { styled } from '@mui/system';
import useEditModal from './hooks/useEditModal';
import ContactEditContextProvider from 'features/Views/ContactEdit/context/ContactEditContextProvider';

interface Props {
  phones: IContactAddress[];
  emails: IContactAddress[];
  facebook: IContactAddress[];
  whatsapp: IContactAddress[];
  mercadlibre: IContactAddress[];
  webchat: IContactAddress[];
  instagram: IContactAddress[];
  rcs: IContactAddress[];
}

const StyledBox = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  paddingTop: theme.spacing(4),
  flexWrap: 'wrap',
  gap: theme.spacing(1.25),
  marginLeft: '5%',
  justifyContent: 'space-between',
  maxWidth: '-webkit-fill-available'
}));

const ContactSection = ({
  phones,
  emails,
  facebook,
  whatsapp,
  mercadlibre,
  webchat,
  instagram,
  rcs
}: Props) => {
  const { t } = useTranslation();
  const { isOpen, title, openModal, closeModal } = useEditModal();
  return (
    <CardSection sectionTitle={t('contactDetails.contactInformation')}>
      <StyledBox className="contacts-rowContacts">
        <ContactMethod
          handleEdit={() => openModal('Phones')}
          title={t('contactDetails.phones')}
          methodItems={phones}
          isPhone={true}
        />
        <ContactMethod
          handleEdit={() => openModal('Emails')}
          title={'Emails'}
          methodItems={emails}
        />
        <ContactMethod
          handleEdit={() => openModal('Facebook')}
          title={'Facebook'}
          methodItems={facebook}
        />
        <ContactMethod
          handleEdit={() => openModal('Whatsapp')}
          title={'Whatsapp'}
          methodItems={whatsapp}
        />
        <ContactMethod
          handleEdit={() => openModal('RCS')}
          title={'RCS'}
          methodItems={rcs}
        />
        <ContactMethod
          handleEdit={() => openModal('Mercado libre')}
          title={'Mercado libre'}
          methodItems={mercadlibre}
        />
        <ContactMethod
          handleEdit={() => openModal('Webchat')}
          title={'Webchat'}
          methodItems={webchat}
        />
        <ContactMethod
          handleEdit={() => openModal('Instagram')}
          title={'Instagram'}
          methodItems={instagram}
        />
      </StyledBox>
      <ContactEditContextProvider>
        <EditModal open={isOpen} onClose={closeModal} title={title} />
      </ContactEditContextProvider>
    </CardSection>
  );
};

export default ContactSection;
