// Types
import { ContactField_type, Property } from '@trii/types/dist/Contacts';
// Components
import { TextField } from '@mui/material';

type CustomPropertyInputProps = Property & {
  onChange: (name: string, value: any) => void;
};

const CustomPropertyInput: React.FC<CustomPropertyInputProps> = ({
  nameKey,
  value,
  onChange,
  type,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      type === ContactField_type.NUMBER_ACCOUNTING ||
      type === ContactField_type.NUMBER_DECIMAL ||
      type === ContactField_type.NUMBER_CURRENCY ||
      type === ContactField_type.CURRENCY
    ) {
      handleNumberTypeChange(event);
    } else if (type === ContactField_type.NUMBER_INTEGER) {
      handleIntegerNumberTypeChange(event);
    } else if (
      type === ContactField_type.LONGTEXT ||
      type === ContactField_type.TEXT
    ) {
      handleTextTypeChange(event);
    }
  };

  const handleTextTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(nameKey, event.target.value);
  };

  const handleNumberTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const sanitizedInput = inputValue.replace(/[^0-9.]/g, '');
    onChange(nameKey, sanitizedInput);
  };

  const handleIntegerNumberTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = event.target.value;
    if (/^\d*$/.test(newValue)) {
      onChange(nameKey, newValue);
    }
  };

  return (
    <TextField
      size="small"
      variant="standard"
      value={value}
      onChange={handleChange}
      inputProps={{
        pattern: '[0-9]*',
      }}
      multiline={type === ContactField_type.LONGTEXT}
      rows={type === ContactField_type.LONGTEXT ? 4 : 1}
      sx={{ width: '100%' }}
    />
  );
};

export default CustomPropertyInput;
