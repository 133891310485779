// Components
import { FormControl, Select, MenuItem } from '@mui/material';

type Props = {
  value: string;
  handleChange: (value: string) => void;
  options: string[];
};

const CustomPropertyOptionsSelect = ({ handleChange, value, options }: Props) => {
  return (
    <FormControl className="fadein" sx={{ width: '100%' }}>
      <Select
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        size="small"
        variant="standard"
      >
        {options.map((option, i) => (
          <MenuItem key={i} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomPropertyOptionsSelect;
