import { useEffect, useState, useCallback } from 'react';
// Components/ui
import {
  Box as MuiBox,
  CircularProgress,
  IconButton as MuiIconButton,
  TextField as MuiTextField,
  Tooltip as MuiTooltip,
  Typography as MuiTypography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import { updateContact } from 'ReduxToolkit/features/contactsSlice/contactsSlice';
import { styled } from '@mui/system';

type NameProps = {
  firstName: string;
  lastName: string;
};

const StyledBox = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(0.5),
  '&:hover .edit-icon': {
    display: 'inline-flex', // Show on hover only
  },
}));

const StyledTextField = styled(MuiTextField)({
  width: '100%',
});

const StyledTypography = styled(MuiTypography)({
  wordWrap: 'break-word',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const StyledIconButton = styled(MuiIconButton)(({ theme }) => ({
  padding: theme.spacing(0.5),
  fontSize: '0.8rem',
  display: 'none', // Hidden by default
  '&.always-visible': {
    display: 'inline-flex', // Always visible if class "always-visible"
  },
  '& .MuiSvgIcon-root': {
    fontSize: '1rem',
  },
}));

const Name = ({ firstName, lastName }: NameProps) => {
  const dispatch = useAppDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [inputFirstName, setInputFirstName] = useState(firstName);
  const [inputLastName, setInputLastName] = useState(lastName);
  const [isLoading, setIsLoading] = useState(false);

  const toggleEditMode = useCallback(() => {
    setIsEditing((prev) => !prev);
    if (isEditing) {
      setInputFirstName(firstName);
      setInputLastName(lastName);
    }
  }, [isEditing, firstName, lastName]);

  const handleFirstNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setInputFirstName(e.target.value);
  }, []);

  const handleLastNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setInputLastName(e.target.value);
  }, []);

  const handleSave = useCallback(async () => {
    if (!inputFirstName.trim() || !inputLastName.trim()) return; // Prevent saving empty names
    setIsLoading(true);

    await dispatch(updateContact({ firstName: inputFirstName, lastName: inputLastName }));

    setIsLoading(false);
    setIsEditing(false);
  }, [dispatch, inputFirstName, inputLastName]);

  useEffect(() => {
    if (!isEditing) {
      setInputFirstName(firstName);
      setInputLastName(lastName);
    }
  }, [firstName, lastName, isEditing]);

  return (
    <StyledBox>
      {isEditing ? (
        <>
          <StyledTextField
            value={inputFirstName}
            onChange={handleFirstNameChange}
            variant="standard"
            size="small"
            disabled={isLoading}
            placeholder="First Name" // Placeholder when empty
          />
          <StyledTextField
            value={inputLastName}
            onChange={handleLastNameChange}
            variant="standard"
            size="small"
            disabled={isLoading}
            placeholder="Last Name" // Placeholder when empty
          />
        </>
      ) : (
        <>
          <MuiTooltip title={firstName} placement='top'>
            <StyledTypography variant="subtitle1">{firstName}</StyledTypography>
          </MuiTooltip>
          <MuiTooltip title={lastName} placement='top'>
            <StyledTypography variant="subtitle1">{lastName}</StyledTypography>
          </MuiTooltip>
        </>
      )}

      <StyledIconButton
        size="small"
        onClick={isEditing ? handleSave : toggleEditMode}
        disabled={isLoading}
        className={isEditing ? "always-visible" : "edit-icon"} // Conditional visibility
      >
        {isLoading ? (
          <CircularProgress size={18} />
        ) : isEditing ? (
          <SaveIcon fontSize="small" />
        ) : (
          <EditIcon fontSize="small" />
        )}
      </StyledIconButton>

      {isEditing && (
        <StyledIconButton
          size="small"
          onClick={toggleEditMode}
          disabled={isLoading}
          className="always-visible" // Always visible while editing
        >
          <CancelIcon fontSize="small" />
        </StyledIconButton>
      )}
    </StyledBox>
  );
};

export default Name;
