// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import AccordionCard from './layout/AccordionCard';
import { Box } from '@mui/material';
import { DetailsCard, FilesCard, NotesCard } from './components';
import {
  DebtsTable,
  DifussionTable,
  RepairTable,
  TicketsTable,
} from './components/Tables';
// Utils
import {
  tableDebts,
  tableDifussion,
  tableRepair,
  tableTickets,
} from './utils/tableItems';
import ActivitiesCard from './components/ActivitiesCard';

const BodyContactViewer = () => {
  const { t } = useTranslation();

  return (
    <Box
      width={'80%'}
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark'
            ? '1e1e1e'
            : theme.palette.background.default,
        overflow: 'auto',
      }}
    >
      <DetailsCard title={t('contactsView.details')} />
      <NotesCard title={t('contactsView.notes.title')} />
      <FilesCard title={t('contactsView.files.title')} />
      <ActivitiesCard title={t('contactsView.activities.title')} />
      {/* diffusion */}
      <AccordionCard title={t('contactsView.diffusion.title')} id="diffusion">
        <DifussionTable tableDifussion={tableDifussion}></DifussionTable>
      </AccordionCard>
      {/* repair */}
      {/* <AccordionCard title={t('contactsView.repair.title')} id="repair">
        <RepairTable tableRepair={tableRepair}> </RepairTable>
      </AccordionCard> */}
      {/* debts */}
      <AccordionCard title={t('contactsView.debts.title')} id="debts">
        <DebtsTable tableDebts={tableDebts}> </DebtsTable>
      </AccordionCard>
      {/* tickets */}
      {/* <AccordionCard title={'Tickets'} id="tickets">
        <TicketsTable tableTickets={tableTickets}> </TicketsTable>
      </AccordionCard> */}
    </Box>
  );
};

export default BodyContactViewer;
