import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRange, MultiInputDateRangeField } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

type Props = {
  value: DateRange<Dayjs> | null;
  handleChange: (newValue: DateRange<Dayjs>) => void;
};

const CustomPropertyDateRangePicker = ({ value, handleChange }: Props) => {
  const { t } = useTranslation();

  // Default date range if value is null
  const initDates: DateRange<Dayjs> = [dayjs(), dayjs()];

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['MultiInputDateRangeField']}>
        <MultiInputDateRangeField
          slotProps={{
            textField: ({ position }) => ({
              label:
                position === 'start' ? t('contactEdit.from') : t('contactEdit.to'),
              size: 'small',
              width: '100%',
              variant: 'standard'

            }),
          }}
          value={value || initDates} // Use initDates as the default if value is null
          onChange={(newValue) => handleChange(newValue)}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default CustomPropertyDateRangePicker;
