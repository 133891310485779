import { IContactAddress } from '@trii/types/dist/Contacts';
import { styled } from '@mui/system';
import { Box, Avatar, IconButton, Modal, Fade, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { useSelector } from 'react-redux';
import { selectContactData } from 'ReduxToolkit/features/contactsSlice/contactsSlice';
import { useState } from 'react';
import { AvatarViewer } from 'features/Views/ContactView/components/HeaderContactViewer/components';
import useSASAvatarURL from 'hooks/useSASAvatarURL';

type Props = {
  item: IContactAddress;
};

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(0.5),
}));

const StyledLocalPhoneIcon = styled(LocalPhoneIcon)(({ theme }) => ({
  height: 16,
  width: 16,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.dropdownMenu,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  color: theme.palette.text.primary,
  width: '50%',
  paddingBottom: '3px',
  minHeight: '50px',
  position: 'relative',

}));

const LeftSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: theme.spacing(1),
  maxWidth: '-webkit-fill-available',

}));

const ContactDetails = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
  overflow: 'hidden',

}));

const AvatarWrapper = styled(Avatar)(({ theme }) => ({
  width: 32,
  height: 32,
  cursor: 'pointer', // Indica que es clickeable
}));

const EllipsisText = styled(Box)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));
const NoteText = styled(EllipsisText)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.text.secondary,
}));
const PhoneText = styled(EllipsisText)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
}));

const ContactMethodPhoneItem = ({ item }: Props) => {
  const { t } = useTranslation();
  const contactData = useSelector(selectContactData);
  const imageURL = item.profileUrl;
  const imageURLWithAccess = useSASAvatarURL(imageURL);

  const [isModalOpen, setModalOpen] = useState(false);

  const handleCallContact = () => {
    const contactPhone = item.address;
    if (!contactPhone) return;

    const event = new CustomEvent('call-contact-header', {
      detail: { phoneAddress: contactPhone },
    });
    window.dispatchEvent(event);
  };

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);
  const stringAvatar = (name: string) => {
    if (name) {
      const firstLetter = `${name.split(' ')[0][0]}`;

      return {
        children: `${firstLetter}`,
      };
    }

    return {
      children: '',
    };
  };
  return (
    <>
      <StyledBox>
        <LeftSection>
          <StyledIconButton
            onClick={handleCallContact}
            color="primary"
            size="small"
          >
            <StyledLocalPhoneIcon />
          </StyledIconButton>
          <ContactDetails>
            <Tooltip title={item.address || ''} arrow>
              <PhoneText>{item.address}</PhoneText>
            </Tooltip>
            <Tooltip title={item.profileName || ''} arrow>
              <NoteText sx={{
                marginTop: '-10px',
                marginLeft: '5px',
                fontSize: '10px'

              }}>{item.profileName}</NoteText>
            </Tooltip>
            <Tooltip title={item.note || ''} arrow>
              <NoteText>{item.note}</NoteText>
            </Tooltip>
          </ContactDetails>
        </LeftSection>
        {imageURL !== '' && (
          <AvatarWrapper
            src={imageURLWithAccess}
            alt={item.profileName}
            onClick={handleOpenModal}
            style={{ cursor: 'pointer' }}
          >
            {item?.profileName?.charAt(0).toUpperCase()}
          </AvatarWrapper>
        )}
      </StyledBox>

      {/* Modal */}
      {imageURL && (
        <AvatarViewer
          open={isModalOpen}
          avatar={contactData.imageUrl !== '' ? imageURLWithAccess : ''}
          name={stringAvatar(item?.profileName).children}
          handleClose={handleCloseModal}
        ></AvatarViewer>
      )}
    </>
  );
};

export default ContactMethodPhoneItem;
