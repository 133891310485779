import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectActivities } from 'ReduxToolkit/features/activitiesSlice/utils/selectors';
import { selectFiles } from 'ReduxToolkit/features/fileSlice/utils/selectors';
import { selectNotes } from 'ReduxToolkit/features/noteSlice/noteSlice';
import {
  sectionMenuOpenDetails,
  sectionMenuOpenNotes,
  sectionMenuOpenFiles,
  sectionMenuOpenTasks,
  sectionMenuOpenActivities,
  sectionMenuOpenDiffusion,
  sectionMenuOpenRepair,
  sectionMenuOpenDebts,
  sectionMenuOpenTickets,
} from 'ReduxToolkit/features/contactsSlice/contactsSlice';

const SectionsMenu = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const activities = useSelector(selectActivities);
  const files = useSelector(selectFiles);
  const notes = useSelector(selectNotes);

  const totalActivities = activities?.length;
  const totalFiles = files?.length;
  const totalNotes = notes?.length;

  const mockList = [
    { name: t('contactsView.details'), id: 'details', totalItems: 0 },
    { name: t('contactsView.notes.title'), id: 'notes', totalItems: totalNotes },
    { name: t('contactsView.files.title'), id: 'files', totalItems: totalFiles },
    { name: t('contactsView.activities.title'), id: 'activities', totalItems: totalActivities },
    { name: t('contactsView.diffusion.title'), id: 'diffusion', totalItems: 0 },
    { name: t('contactsView.repair.title'), id: 'repair', totalItems: 0 },
    { name: t('contactsView.debts.title'), id: 'debts', totalItems: 0 },
    { name: 'Tickets', id: 'tickets', totalItems: 0 },
  ];

  const navCard = useCallback(
    (value) => {
      try {
        const anchor = document.querySelector('#' + value);
        switch (value) {
          case 'details':
            dispatch(sectionMenuOpenDetails(true));
            break;
          case 'notes':
            dispatch(sectionMenuOpenNotes(true));
            break;
          case 'files':
            dispatch(sectionMenuOpenFiles(true));
            break;
          case 'tasks':
            dispatch(sectionMenuOpenTasks(true));
            break;
          case 'activities':
            dispatch(sectionMenuOpenActivities(true));
            break;
          case 'diffusion':
            dispatch(sectionMenuOpenDiffusion(true));
            break;
          case 'repair':
            dispatch(sectionMenuOpenRepair(true));
            break;
          case 'debts':
            dispatch(sectionMenuOpenDebts(true));
            break;
          case 'tickets':
            dispatch(sectionMenuOpenTickets(true));
            break;
          default:
            dispatch(sectionMenuOpenDetails(true));
            break;
        }
        anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } catch (error) {}
    },
    [dispatch]
  ); //eslint-disable-line

  return (
    <Box
      width={'20%'}
      sx={{
        borderRight: '1px solid',
        borderColor: (theme) => theme.palette.divider,
        maxHeight: 'inherit',
        overflow: 'auto',
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: 360,
          // @ts-ignore
          backgroundColor: (theme) => theme.palette.background.default,
          backgroundImage:
            'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
        }}
      >
        <nav aria-label="secondary mailbox folders">
          <List sx={{ paddingTop: '0px' }}>
            <ListSubheader
              sx={{
                fontSize: '16px',
                // @ts-ignore
                bgcolor: (theme) => theme.palette.background.panel,
                color: (theme) => theme.palette.text.primary,
              }}
            >
              {t('contactsView.sections')}
            </ListSubheader>
            {mockList.map((value) => (
              <ListItem
                disablePadding
                sx={{ color: (theme) => theme.palette.text.primary }}
                key={value.id}
              >
                <ListItemButton
                  sx={{
                    py: 0,
                    pl: 3,
                    minHeight: 32,
                    // @ts-ignore
                    color: (theme) => theme.palette.color.secondary,
                  }}
                  onClick={() => navCard(value.id)}
                  disabled={value.id === 'tickets' || value.id === 'repair'} // Deshabilitar si el id es "ticket" o "repair"
                >
                  <ListItemText
                    primary={`${value.name}${
                      value.totalItems > 0 ? ` (${value.totalItems})` : ''
                    }`}
                    primaryTypographyProps={{ fontSize: 14 }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </nav>
      </Box>
    </Box>
  );
};

export default SectionsMenu;
