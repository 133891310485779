import { useTranslation } from 'react-i18next';
// Components/ui
import { styled } from '@mui/system';
import { Box, Typography, Button, IconButton } from '@mui/material';
import { ContactMethodItem, ContactMethodPhoneItem } from './components';
// Types
import { IContactAddress } from '@trii/types/dist/Contacts';
import EditIcon from '@mui/icons-material/Edit';

interface Props {
  title: string;
  methodItems: IContactAddress[];
  isPhone?: boolean;
  handleEdit: () => void;
}

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1, 0, 2, 0),
  width: '100%',
  gap: theme.spacing(1),
  '&:hover .edit-icon': {
    opacity: 1,
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  letterSpacing: -0.5,
  fontSize: 14,
  fontWeight: 600,
}));

const HeaderBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
 
}));

const MethodItemsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  width: '100%',
}));
const StyledIconButton = styled(IconButton)({
  opacity: 0,
  transition: 'opacity 0.3s ease-in-out',
  '& .MuiSvgIcon-root': {
    width: '0.8em',
    height: '0.8em',
  },
  marginLeft: '15px'

});

const ContactMethod = ({ title, methodItems, isPhone, handleEdit }: Props) => {
  const { t } = useTranslation();
  return (
    <StyledBox>
      <HeaderBox >
        <StyledTypography>{title}</StyledTypography>
        <StyledIconButton className="edit-icon" size="small" onClick={handleEdit}>
          <EditIcon fontSize="small" />
        </StyledIconButton>
      </HeaderBox>
      <MethodItemsBox>
        {methodItems.map((item, index) => {
          if (isPhone) {
            return <ContactMethodPhoneItem key={index} item={item} />;
          }
          return <ContactMethodItem key={index} item={item} />;
        })}
      </MethodItemsBox>
    </StyledBox>
  );
};

export default ContactMethod;
