import { Box, Card, Theme, Typography } from '@mui/material';
import { style } from './style';
// Redux
import { useDispatch } from 'react-redux';
// Types
import { ReactNode, MouseEvent } from 'react';

interface AccordionCardProps {
  children: ReactNode;
  title: string;
  id: string;
  button?: ReactNode;
  totalItems?: number;
}

const AccordionCard = ({
  children,
  title,
  id,
  button,
  totalItems,
}: AccordionCardProps) => {
  const handleButtonClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <Card
      sx={{
        ...style.cardContainer,
        border: '1px solid',
        borderColor: 'divider',
        overflow: 'hidden',
      }}
      id={id}
    >
      <Box
        sx={{
          //@ts-ignore
          backgroundColor: (theme: Theme) => theme.palette.background.accordionHeader,

          padding: 1.5,
          paddingX: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
            {title}
          </Typography>
          {totalItems !== undefined && totalItems !== null && totalItems > 0 && (
            <Typography sx={{ marginLeft: '5px', fontWeight: 500 }} variant="subtitle2">
              ({totalItems})
            </Typography>
          )}
        </Box>
        {button && <div onClick={(e) => handleButtonClick(e)}>{button}</div>}
      </Box>
      <Box
      >
        {children}
      </Box>
    </Card>
  );
};

export default AccordionCard;
