import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
// Redux
import useCreateBusiness from 'features/Views/BusinessCreate/hooks/useCreateBusiness/useCreateBusiness';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { createBusiness } from 'ReduxToolkit/features/businessSlice/businessSlice';
// Slice
import {
  selectBusiness,
  selectBusinessFetchStatus,
  fetchBusinessTableData,
  selectPagination,
  selectContactsTableData,
} from 'ReduxToolkit/features/contactsSlice/contactsSlice';
// Components/ui
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  LinearProgress,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { BusinessSearchBar, BusinessSearchResults } from './components';
import { LoadingButton } from '@mui/lab';
// Types
import { Pagination } from 'ReduxToolkit/features/contactsSlice/types/Pagination';
import { IBusiness, IContactInfo } from '@trii/types/dist/Contacts';
import { UIEvent } from 'react';
import UseEditContact from 'features/Views/ContactEdit/hooks/useEditContact/types/UseEditContact';
import UseCreateContact from 'features/Views/ContactCreate/hooks/useCreateContact/types/UseCreateContact';
import { SimplifiedBusiness } from './types';
import { Clear } from '@mui/icons-material';

interface Props {
  contact?: UseEditContact | UseCreateContact;
}

const StyledMessageContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  padding: '16px',
});

const BusinessSelect = ({ contact }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const containerRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [globalTimer, setGlobalTimer] = useState<NodeJS.Timeout | null>(null);
  const [isLoadingTimer, setIsLoadingTimer] = useState<boolean>(false);
  const [hasSearched, setHasSearched] = useState<boolean>(false);
  const [allLoaded, setAllLoaded] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');

  const [businessList, setBusinessList] = useState<SimplifiedBusiness[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isNextPage, setIsNextPage] = useState<boolean>(false);

  const currentBusiness = useSelector(selectBusiness);
  const businessFetchStatus = useSelector(selectBusinessFetchStatus);
  const pagination = useSelector(selectPagination);
  const contactsTableData = useSelector(selectContactsTableData);

  const businessHelper = useCreateBusiness();

  const isLoading = businessFetchStatus === 'loading';

  const handleCreateNewBusiness = async () => {
    const newBusiness = businessHelper.action.getBusiness();

    try {
      const response = await dispatch(createBusiness(newBusiness));
      const createdBusiness = response.payload as IBusiness;

      setBusinessList([
        {
          businessId: createdBusiness.id,
          businessName: createdBusiness.name,
        },
      ]);
    } catch (error) {
      console.error('Error creating business: ', error);
    }
  };

  const handleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setInputValue(value);
    setIsNextPage(false);
    setAllLoaded(false);
    setCurrentPage(1);
    setBusinessList([]);
    setHasSearched(false);

    if (!value) return;
    if (globalTimer) clearTimeout(globalTimer);

    setIsLoadingTimer(true);

    const timer = setTimeout(async () => {
      const newPagination: Pagination = {
        ...pagination,
        format: 'IBusiness',
        currentPage: 1,
        filter: [
          {
            value,
            column: 'name',
            condition: '',
          },
        ],
      };
      await dispatch(fetchBusinessTableData(newPagination));

      setHasSearched(true);

      setIsLoadingTimer(false);
    }, 3000);

    setGlobalTimer(timer);

    return () => clearTimeout(timer);
  };

  function handleClose() {
    setInputValue('');
    setHasSearched(false);
    setBusinessList([]);
  }

  const handleScroll = async (e: UIEvent<HTMLElement>) => {
    const element = e.target as HTMLElement;
    const bottom = element.scrollHeight - element.scrollTop === element.clientHeight;

    if (bottom && !allLoaded) {
      const newPage = currentPage + 1;

      setIsNextPage(true);
      setCurrentPage(newPage);

      const newPagination: Pagination = {
        ...pagination,
        format: 'IBusiness',
        currentPage: newPage,
        filter: [
          {
            value: inputValue,
            column: 'name',
            condition: '',
          },
        ],
      };

      await dispatch(fetchBusinessTableData(newPagination));
    }
  };

  useEffect(() => {
    businessHelper.field.name.actions.changeValue(inputValue || '');
  }, [inputValue]);

  useEffect(() => {
    if (currentBusiness && currentBusiness.length > 0) {
      const simplifiedCurrentBusiness: SimplifiedBusiness[] = currentBusiness.map(
        (business) => ({
          businessId: business.id,
          businessName: business.name,
        })
      );

      if (isNextPage) {
        if (contactsTableData?.paginacion.total === businessList.length) {
          setAllLoaded(true);
          return;
        }

        setBusinessList([...businessList, ...simplifiedCurrentBusiness]);
      } else {
        setBusinessList(simplifiedCurrentBusiness);
      }
    }
  }, [currentBusiness]);

  const CustomNoOptionsMessage = () => {
    return (
      <StyledMessageContainer>
        <Typography variant="body1">{t('global.noOptions')}</Typography>
        <LoadingButton
          loading={isLoading}
          disabled={isLoading}
          variant="contained"
          color="primary"
          onClick={handleCreateNewBusiness}
        >
          {t('businessDetails.createBusiness')}
        </LoadingButton>
      </StyledMessageContainer>
    );
  };

  return (
    <Box display="flex" sx={{ marginBottom: '15px' }} flexDirection="column">
      <Box
        ref={containerRef}
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        <Box position="absolute" top="2rem" width="100%">
          {isLoading && (
            <LinearProgress
              sx={{
                borderRadius: 1,
              }}
            />
          )}
        </Box>
        <Autocomplete
          id="asynchronous-search-business"
          multiple={false}
          value={contact.field.selectedBusiness[0] || null}
          onChange={(e, newValue) => contact.action.setSelectedBusiness([newValue])}
          noOptionsText={
            hasSearched && businessList.length === 0 && <CustomNoOptionsMessage />
          }
          options={businessList}
          getOptionLabel={(option) => option.businessName}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                clickable={false}
                label={option.businessName}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('contactEdit.business')}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              ref={inputRef}
              className="contacts-smallInput"
              onChange={handleSearch}
            />
          )}
          ListboxProps={{
            sx: {
              maxHeight: '15rem',
              overflowY: 'auto',
            },
            onScroll: (e) => handleScroll(e),
          }}
          onClose={handleClose}
          onOpen={handleClose}
          clearIcon={
            (isLoading && inputRef.current?.value === inputValue) ||
            isLoadingTimer ? (
              <CircularProgress size={20} />
            ) : (
              <Clear
                sx={{
                  color: (theme) => theme.palette.text.disabled,
                }}
                fontSize="small"
              />
            )
          }
        />
      </Box>
    </Box>
  );
};

export default BusinessSelect;
