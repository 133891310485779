import { memo } from 'react';

// Provider
import FilesCardContextProvider from './context/FilesCardContextProvider';
// Redux
import { useSelector } from 'react-redux';
import { selectFiles } from 'ReduxToolkit/features/fileSlice/utils/selectors';
// Components/ui
import { FilesTable, UploadButton } from './components';
import { AccordionCard } from '../../layout';

interface FilesCardProps {
  title: string;
}
const FilesCard = memo(({ title }: FilesCardProps) => {
  const files = useSelector(selectFiles);

  const totalFiles = files?.length;

  return (
    <FilesCardContextProvider>
      <AccordionCard
        title={title}
        id="files"
        button={<UploadButton />}
        totalItems={totalFiles}
      >
        <FilesTable tableFiles={files} />
      </AccordionCard>
    </FilesCardContextProvider>
  );
});

export default FilesCard;
