import { useEffect, useState } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Redux
import {
  getShowBusinessSearchModal,
  selectContactData,
  selectContactFetchStatus,
} from 'ReduxToolkit/features/contactsSlice/contactsSlice';
import { useSelector } from 'react-redux';
import { selectCustomContactFields } from 'ReduxToolkit/features/contactsSlice/contactsSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  fetchUserById,
  selectUser,
  selectUsers,
} from 'ReduxToolkit/features/userSlice/userSlice';
// Components/ui
import { Box, Divider, Skeleton } from '@mui/material';
import { CardSection } from './layout';
import { ContactInfoLabel, ContactCustomFieldLabel } from '../../layout';
import { AccordionCard } from '../../layout';
// Utils
import { isIContact } from 'utils/isIContact';
import { BusinessSelectModal, ContactSection } from './components';
// Hooks
import useEditContact from 'features/Views/ContactEdit/hooks/useEditContact/useEditContact';
// Types
import { UserTrii } from '@trii/types/dist/Users';

const skeletonMock = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

interface DetailsCardProps {
  title: string;
}

const DetailsCard = ({ title }: DetailsCardProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const contactData = useSelector(selectContactData);
  const contactViewerFetchStatus = useSelector(selectContactFetchStatus);
  const user = useSelector(selectUser);
  const users = useSelector(selectUsers);
  const showBusinessSearchModal = useSelector(getShowBusinessSearchModal);

  const editContact = useEditContact(contactData);

  const [owner, setOwner] = useState<string>(null);
  const [updatedBy, setUpdatedBy] = useState<string>(null);
  const [createdBy, setCreatedBy] = useState<string>(null);

  const birthDate = contactData.birthDate ? new Date(contactData.birthDate) : null;
  const dateOnlyString = birthDate ? birthDate.toLocaleDateString() : null;
 
  const Skeletons = skeletonMock.map((item, i) => {
    return (
      <Skeleton
        height={60}
        width={350}
        // @ts-ignore
        sx={{ backgroundColor: (theme) => theme.palette.Skeleton.bg }}
        animation="wave"
        key={i}
      />
    );
  });

  const getUserById = async (userId): Promise<UserTrii> => {
    const response = await dispatch(fetchUserById(userId));
    return response.payload as UserTrii;
  };

  const calculateAge = (birthDate: Date) => {
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  useEffect(() => {
    async function fetchUpdatedByUser() {
      if (contactData.updatedBy) {
        if (contactData.updatedBy === user.uid) {
          setUpdatedBy(user.display_name || user.email);
          return;
        }

        const updatedByUser = await getUserById(contactData.updatedBy);
        setUpdatedBy(updatedByUser.display_name || updatedByUser.email);
      }
    }
    async function fetchCreayByUser() {
      if (contactData.createdBy) {
        if (contactData.createdBy === user.uid) {
          setCreatedBy(user.display_name || user.email);
          return;
        }

        const createdByUser = await getUserById(contactData.createdBy);
        setCreatedBy(createdByUser.display_name || createdByUser.email);
      }
    }
    async function fetchOwner() {
      if (contactData.owner) {
        if (contactData.owner === user.uid) {
          setOwner(user.display_name || user.email);
          return;
        }

        const owner = await getUserById(contactData.owner);
        setOwner(owner.display_name || owner.email);
      }
    }

    fetchUpdatedByUser();
    fetchCreayByUser();
    fetchOwner();
  }, [contactData]);

  return (
    <>
      <AccordionCard title={title} id="details">
        <CardSection sectionTitle="General">
          <Box
            display={'flex'}
            flexDirection={'row'}
            pt={4}
            flexWrap={'wrap'}
            justifyContent={'space-between'}
            sx={{ gap: 1,
              maxWidth: '-webkit-fill-available'
             }}
            ml={'5%'}
            className="contacts-rowContacts"
            
          >
            {contactViewerFetchStatus === 'succeeded' ? (
              <>
                <ContactInfoLabel
                  title={t('contactDetails.contactOwner')}
                  displayValue={owner || '-'}
                  value={contactData.owner}
                  inputType="select"
                  options={users}
                  constructUpdateObject={(value: string) => ({
                    owner: value,
                  })}
                />
                <ContactInfoLabel
                  title={t('contactDetails.business')}
                  displayValue={contactData.businessName || '-'}
                  value={contactData.businessId}
                  inputType="selectBusiness"
                  // options={}
                />
                <ContactInfoLabel
                  title={t('contactDetails.email')}
                  value={contactData.email}
                  isNotEditable={true}
                />
                <ContactInfoLabel
                  title={t('contactDetails.phone')}
                  value={contactData.phone}
                  isNotEditable={true}
                />
                <ContactInfoLabel
                  title={t('contactDetails.createdBy')}
                  value={createdBy || '-'}
                  // date={contactData.createdAt}
                  isNotEditable={true}
                />
                <ContactInfoLabel
                  title={t('contactDetails.modifiedBy')}
                  value={updatedBy || '-'}
                  // date={contactDataUpdate_at}
                  isNotEditable={true}
                />
                <ContactInfoLabel
                  title={t('global.birthdate')}
                  inputType="birthday"
                  displayValue={
                    birthDate
                      ? `${dateOnlyString} (${calculateAge(birthDate)})`
                      : '-'
                  }
                  value={birthDate?.toDateString() || new Date().toDateString()}
                  constructUpdateObject={(value: Date) => ({
                    birthDate: value,
                  })}
                  contact={editContact}
                />
                <Box className="contacts-col-5" height={'80px'} />
                {editContact.field.customProperties.map((customProperty, i) => (
                  <ContactCustomFieldLabel
                    contact={editContact}
                    customProperty={customProperty}
                    key={i}
                  />
                ))}
              </>
            ) : contactViewerFetchStatus === 'loading' ? (
              <>{Skeletons}</>
            ) : null}
          </Box>
        </CardSection>
        <Divider />
        <CardSection sectionTitle={t('contactDetails.addressInformation')}>
          <Box
            display={'flex'}
            flexDirection={'row'}
            pt={4}
            flexWrap={'wrap'}
            sx={{ gap: 1,
              maxWidth: '-webkit-fill-available'
             }}
            ml={'5%'}
            className="contacts-rowContacts"
            justifyContent={'space-between'}
          >
            {contactViewerFetchStatus === 'succeeded' && isIContact(contactData) ? (
              <>
                <ContactInfoLabel
                  title={t('contactDetails.street')}
                  value={contactData.address1?.street}
                  constructUpdateObject={(value: string) => ({
                    address1: { street: value },
                  })}
                />
                <ContactInfoLabel
                  title={t('contactDetails.anotherStreet')}
                  value={contactData.address2?.street}
                  constructUpdateObject={(value: string) => ({
                    address2: { street: value },
                  })}
                />
                <ContactInfoLabel
                  title={t('contactDetails.city')}
                  value={contactData.address1?.city}
                  constructUpdateObject={(value: string) => ({
                    address1: { city: value },
                  })}
                />
                <ContactInfoLabel
                  title={t('contactDetails.anotherCity')}
                  value={contactData.address2?.city}
                  constructUpdateObject={(value: string) => ({
                    address2: { city: value },
                  })}
                />
                <ContactInfoLabel
                  title={t('contactDetails.state')}
                  value={contactData.address1?.state}
                  constructUpdateObject={(value: string) => ({
                    address1: { state: value },
                  })}
                />
                <ContactInfoLabel
                  title={t('contactDetails.anotherState')}
                  value={contactData.address2?.state}
                  constructUpdateObject={(value: string) => ({
                    address2: { state: value },
                  })}
                />
                <ContactInfoLabel
                  title={t('contactDetails.postcode')}
                  value={contactData.address1?.zipcode}
                  constructUpdateObject={(value: string) => ({
                    address1: { zipcode: value },
                  })}
                />
                <ContactInfoLabel
                  title={t('contactDetails.anotherPostcode')}
                  value={contactData.address2?.zipcode}
                  constructUpdateObject={(value: string) => ({
                    address2: { zipcode: value },
                  })}
                />
                <ContactInfoLabel
                  title={t('contactDetails.country')}
                  value={contactData.address1?.country}
                  constructUpdateObject={(value: string) => ({
                    address1: { country: value },
                  })}
                />
                <ContactInfoLabel
                  title={t('contactDetails.anotherCountry')}
                  value={contactData.address2?.country}
                  constructUpdateObject={(value: string) => ({
                    address2: { country: value },
                  })}
                />
              </>
            ) : contactViewerFetchStatus === 'loading' ? (
              <>{Skeletons}</>
            ) : null}
          </Box>
        </CardSection>
        <Divider />
        <ContactSection
          facebook={contactData?.ims_facebook}
          whatsapp={contactData?.ims_whatsapp}
          mercadlibre={contactData?.ims_mercadolibre}
          webchat={contactData?.ims_webchat}
          instagram={contactData?.ims_instagram}
          emails={contactData?.emails}
          phones={contactData?.phones}
          rcs={contactData?.ims_rcs}
        />
      </AccordionCard>
      {showBusinessSearchModal && (
        <BusinessSelectModal open={showBusinessSearchModal} />
      )}
    </>
  );
};

export default DetailsCard;
