import {
  Avatar as MuiAvatar,
  IconButton as MuiIconButton,
  Skeleton as MuiSkeleton,
  Tooltip,
} from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { styled } from '@mui/system';
import { useEffect, useState } from 'react';

// Hooks
import useImage from 'hooks/useImage';
import zIndex from '@mui/material/styles/zIndex';
import useSAS from 'hooks/useSAS';

type ContactAvatarProps = {
  handleClick: () => void;
  src: string;
  alt: string;
  loading: boolean;
};


const AvatarContainer = styled('div')({
  position: 'relative',
  display: 'inline-block',
  width: 50,
  height: 50,
  '&:hover .iconOverlay': {
    display: 'flex',
  },
});

const StyledAvatar = styled(MuiAvatar)(({ theme }) => ({
  width: '100%',
  height: '100%',
  cursor: 'pointer',
  border: '1px solid #ced1d5',
  backgroundColor: `${theme.palette.primary.main}50`,
  color: theme.palette.text.secondary,
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    color: 'rgba(0, 0, 0, 0.025)'
  },

}));

const StyledSkeleton = styled(MuiSkeleton)({
  width: '100%',
  height: '100%',
  variant: 'circular',
  backgroundColor: '#f5f5f5',
  animation: 'wave',
});

const IconOverlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  display: 'none', // Initially hidden
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  borderRadius: '50%', // Circular background
  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent overlay
  color: '#fff',
  alignItems: 'center',
  justifyContent: 'center',
 
}));

const CenterIcon = styled(MuiIconButton)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: '#ffffff',
  padding: '17px'
  
  
});

const BottomLeftIcon = styled(MuiIconButton)(({ theme }) => ({
  position: 'absolute',
  bottom: '-10px',
  left: '-5px',
  color: '#ffffff',
  backgroundColor: '#bdbdbd',
  zIndex: 2,
  '&:hover': {
    color: theme.palette.primary.main, 
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 2
  },
  '.MuiSvgIcon-root': {
    width: '0.8em',
    height: '0.8em',
  }
}));

const BottomRightIcon = styled(MuiIconButton)(({ theme }) => ({
  position: 'absolute',
  bottom: '-10px',
  right: '-5px',
  color: '#ffffff',
  backgroundColor: '#bdbdbd',
  zIndex: 2,
  '&:hover': {
    color: theme.palette.error.main, 
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 2
  },
  '.MuiSvgIcon-root': {
    width: '0.8em',
    height: '0.8em',
  }
}));

const HiddenInput = styled('input')({
  display: 'none',
});

const ContactAvatar = ({ handleClick, src, alt, loading }: ContactAvatarProps) => {
  const imageHandler = useImage(src);
  const [imageURLWithAccess, setImageURLWithAccess] = useState('');
  const URLHandler = useSAS();

  useEffect(() => {
    if (src) {
      URLHandler.getURLWithAccessToken(src).then((url) => {
        setImageURLWithAccess(url);
      });
    }
  }, []);

  return (
    <AvatarContainer>
      {loading ? (
        <StyledSkeleton />
      ) : (
        <StyledAvatar src={imageURLWithAccess} alt={alt} onClick={handleClick} />
      )}

      <IconOverlay className="iconOverlay">
        <Tooltip title="View" placement='top'>
          <CenterIcon size="small" disableRipple onClick={handleClick}>
            <VisibilityIcon fontSize="small" />
          </CenterIcon>
        </Tooltip>
        <Tooltip title="Delete">
          <BottomRightIcon size="small" onClick={imageHandler.action.deleteImage}>
            <DeleteOutlineIcon fontSize="small"/>
          </BottomRightIcon>
        </Tooltip>
        <Tooltip title="Upload">
          <BottomLeftIcon size="small" onClick={imageHandler.action.uploadImage}>
            <CameraAltIcon fontSize="small" />
          </BottomLeftIcon>
        </Tooltip>
      </IconOverlay>
      <HiddenInput accept="image/*" {...imageHandler.inputAtributes} />

    </AvatarContainer>
  );
};

export default ContactAvatar;
