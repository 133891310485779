import { useContext, useState, useEffect } from 'react';
import { ContactEditContext } from 'features/Views/ContactEdit/context/ContactEditContext';
// Components/ui
import { CountrySelect } from './components';
import { WarningsDisplay } from 'components/ContactCreateEditAppBody/components/InformationSection/layout';
import { Box } from '@mui/material';
import {
  InformationItemContainer,
  NoteDeleteButton,
  InformationItemInputsContainer,
} from '../../../../../../layout';
import phoneStyle from './style';
// Types
import { NewAddress } from 'types/NewAddress';
import PhoneInput from 'components/ContactCreateEditAppBody/components/InformationSection/layout/PhoneInput';

type Props = {
  data: NewAddress;
};

const Phone = ({ data }: Props) => {
  const mode = 'edit';
  const { editContact } = useContext(ContactEditContext);
  const editContactActions = editContact.action;
  const [warnings, setWarnings] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>(data.address || '');
  const [checked, setChecked] = useState(true);

  useEffect(() => {
    setChecked(warnings.length === 0 && inputValue !== '');
  }, [warnings, inputValue]);

  const handleInputChange = (e, id, channelType) => {
    const value = e.target.value;
    setInputValue(value);
    editContactActions.handleAddressFieldChange(e, id, channelType);
  };

  return (
    <InformationItemContainer>
      <InformationItemInputsContainer>
        <Box sx={phoneStyle.numberCountryContainer}>
          <CountrySelect />
          <Box sx={phoneStyle.adressInputBox}>
            <PhoneInput
              data={{ ...data, address: inputValue }}
              mode={mode}
              setWarnings={setWarnings}
              onChange={handleInputChange}
            />
            {warnings.length > 0 && (
              <Box sx={phoneStyle.warningMessage}>
                <WarningsDisplay warnings={warnings} />
              </Box>
            )}
          </Box>
        </Box>
        <NoteDeleteButton
          mode={mode}
          addressData={data}
          setWarnings={setWarnings}
          handleNoteFieldChange={editContactActions.handleNoteFieldChange}
          handleDeleteAddress={editContactActions.handleDeleteAddress}
          isDeletingAddress={editContact.state.isDeletingAddress}
          replaceAddress={editContactActions.replaceAddress}
          addWhatsappVerified={editContactActions.addWhatsappVerified}
          checked={checked}
        />
      </InformationItemInputsContainer>
    </InformationItemContainer>
  );
};

export default Phone;
