import { useCallback, useEffect, useState } from 'react';
// Components/ui
import {
  Box,
  CircularProgress,
  Modal,
  AvatarGroup as MuiAvatarGroup,
  Chip as MuiChip,
  IconButton as MuiIconButton,
  Slide,
} from '@mui/material';
import { styled } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { LabelModal, TagSelect } from './components';
// Types
import { ILabel } from '@trii/types/dist/Conversations';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  selectContactData,
  updateContact,
} from 'ReduxToolkit/features/contactsSlice/contactsSlice';
import { ItemWithId, useMultipleSelect } from 'hooks/useMultipleSelect';
import { useSelector } from 'react-redux';
import LabelIcon from '@mui/icons-material/Label';
interface LabelsProps {
  labels: ILabel[];
}

const StyledAvatarGroup = styled(MuiAvatarGroup)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end', // Por alguna razón, los hijos se justifican al revés por defecto
  alignItems: 'center',
  gap: '5px',
  '& .MuiAvatar-circular': {
    width: '30px',
    height: '28px',
    marginLeft: '10px'
  }
}));

const StyledChip = styled(MuiChip)(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.text.primary,
  border: '1px solid rgba(0, 0, 0, 0.1)',
  borderRadius: '4px',
  height: '20px',
  fontSize: '0.7125rem',
}));

const StyledIconButton = styled(MuiIconButton)(({ theme }) => ({
  padding: theme.spacing(0.5), // Smaller padding
  fontSize: '0.8rem', // Adjusted font size
  '& .MuiSvgIcon-root': {
    fontSize: '1rem', // Smaller icons
  },
}));

const Labels = ({ labels }: LabelsProps) => {
  const dispatch = useAppDispatch();

  const [openModal, setOpenModal] = useState(false);
  const [openModalTagSelect, setOpenModalTagSelect] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [maxAvatars, setMaxAvatars] = useState(7);

  const selectedContact = useSelector(selectContactData);

  const selectHandler = useMultipleSelect({
    initialState: selectedContact?.tags as ItemWithId[],
  });

  const toggleEditMode = useCallback(() => {
    setIsEditing((prev) => !prev);
    if (isEditing) selectHandler.actions.setValue(selectedContact.tags); // Reset input if canceled
    setOpenModalTagSelect((prev) => !prev);

  }, [isEditing, labels]);

  const handleSave = useCallback(async () => {
    setIsLoading(true);

    await dispatch(
      updateContact({
        tags: selectHandler.attributes.value.map((tag) => ({
          id: tag.id,
          name: tag.name,
        })),
      })
    );

    setIsLoading(false);
    setIsEditing(false);
    setOpenModalTagSelect((prev) => !prev);

  }, [dispatch, selectHandler.attributes.value, isEditing]);

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };
  const handleOpenTagSelect = () => {
    setOpenModalTagSelect(true);
  };

  const handleCloseTagSelect = () => {
    setOpenModalTagSelect(false);
  };

  useEffect(() => {
    // Function to update maxAvatars based on window width
    const updateMaxAvatars = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth >= 1024 && windowWidth <= 1240) {
        setMaxAvatars(7);
      } else {
        setMaxAvatars(11);
      }
    };

    // Add event listener for window resize
    window.addEventListener('resize', updateMaxAvatars);

    // Call the function initially to set the initial value
    updateMaxAvatars();

    // Remove the event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateMaxAvatars);
    };
  }, []);

  useEffect(() => {
    if (selectedContact?.tags) {
      selectHandler.actions.setValue(selectedContact.tags);
    }
  }, [selectedContact]);

  return (
    <>
      <Box
        display={"flex"}
        gap={2}
        alignItems={"center"}
        sx={{
          "&:hover .edit-icon": {
            opacity: 1,
          },
        }}
      >
        <StyledAvatarGroup onClick={handleOpen} max={maxAvatars}>
          {labels.map((label) => (
            <StyledChip key={label.id} label={label.name} size="small" />
          ))}
        </StyledAvatarGroup>

        <StyledIconButton
          size="small"
          onClick={isEditing ? handleSave : toggleEditMode}
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress size={18} />
          ) : labels.length === 0 ? ( // Condición para mostrar LabelIcon cuando no hay etiquetas
            <LabelIcon fontSize="small" />
          ) : (
            <EditIcon
              sx={{
                opacity: 0,
                transition: "opacity 0.3s ease-in-out",
              }}
              className="edit-icon"
              fontSize="small"
            />
          )}
        </StyledIconButton>
      </Box>
      <LabelModal labels={labels} open={openModal} handleClose={handleClose} />
      <Modal
        open={openModalTagSelect}
        onClose={() => handleClose()}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "start",
          marginTop: "5rem",
        }}
      >
        <Slide
          direction="down"
          in={openModalTagSelect}
          mountOnEnter
          unmountOnExit
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              maxWidth: "30rem",
              maxHeight: "40rem",
              minHeight: "10rem",
              overflow: "auto",
              borderRadius: 2,
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",

              outline: "none",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: "1em",
              }}
            >
              <TagSelect selectHandler={selectHandler} />
              <StyledIconButton
                size="small"
                onClick={handleSave}
                disabled={isLoading}
              >
                <SaveIcon fontSize="small" />
              </StyledIconButton>
              <StyledIconButton
                size="small"
                onClick={toggleEditMode}
                disabled={isLoading}
              >
                <CancelIcon fontSize="small" />
              </StyledIconButton>
            </Box>
          </Box>
        </Slide>
      </Modal>
    </>
  );
};

export default Labels;
