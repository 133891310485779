// Components/ui
import { Phone } from './components';
import { SubsectionBodyContainer } from '../../../../layout';
// Types
import { NewAddress } from 'types/NewAddress';

type Props = {
  phoneNumbers: NewAddress[];
};

const Body = ({ phoneNumbers }: Props) => {
  return (
    <SubsectionBodyContainer variant="large">
      {phoneNumbers?.map((phone) => (
        <Phone key={phone.id} data={phone} />
      ))}
    </SubsectionBodyContainer>
  );
};

export default Body;
