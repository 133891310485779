// Types
import { IContactAddress } from '@trii/types/dist/Contacts';
// Components/ui
import { styled } from '@mui/system';
import { Box, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

type Props = {
  item: IContactAddress;
};

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
}));

const AddressBox = styled(Box)(({ theme }) => ({
  width: '40%',
}));

const NoteBox = styled(Box)(({ theme }) => ({
  width: '40%',
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const StyledLocalPhoneIcon = styled(LocalPhoneIcon)(({ theme }) => ({
  height: 14,
  width: 14,
}));

const ContactMethodPhoneItem = ({ item }: Props) => {
  const { t } = useTranslation();
  const handleCallContact = () => {
    const contactPhone = item.address;

    if (!contactPhone) return;

    const event = new CustomEvent('call-contact-header', {
      detail: { phoneAddress: contactPhone },
    });

    window.dispatchEvent(event);
  };

  return (
    <StyledBox>
      <AddressBox>
        <StyledIconButton onClick={handleCallContact} color="primary" size="small">
          <StyledLocalPhoneIcon />
        </StyledIconButton>
        {item.address}
      </AddressBox>
      <NoteBox>
        {t('global.note')}: {item.note}
      </NoteBox>
    </StyledBox>
  );
};

export default ContactMethodPhoneItem;
