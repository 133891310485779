export const style = {
    titleContainter: {
      display: 'flex',
      maxWidth: '40vw',
      marginLeft: '16px',    
    },
    businessTitle: {
        marginLeft: '15px',
        fontWeight: 'bold',
    wordWrap: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    }
  };
  