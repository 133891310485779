import { useState } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  selectAllLabels,
  selectLabelCreateStatus,
  createLabel,
} from 'ReduxToolkit/features/labelSlice/labelSlice';
// Components/ui
import { Chip, TextField, Autocomplete, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// Types
import type { NewBasicLabel } from 'ReduxToolkit/features/labelSlice/types/NewBasicLabel';
import { ItemWithId, UseMultipleSelectReturnType } from 'hooks/useMultipleSelect';

type TagSelectProps = {
  selectHandler: UseMultipleSelectReturnType<ItemWithId[]>;
};

const TagSelect = ({ selectHandler }: TagSelectProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [searchValue, setSearchValue] = useState<string>('');
  const [areThereResults, setAreThereResults] = useState(true);

  const labels = useSelector(selectAllLabels);
  const labelCreateStatus = useSelector(selectLabelCreateStatus);

  const isLoading = labelCreateStatus === 'loading';
  const isDisabled = areThereResults || isLoading;

  const labelExists = (name: string): boolean =>
    labels?.some((label) => label.name === name);

  const handleSearchChange = (e) => {
    const newValue = e.target.value;

    if (newValue === '') setAreThereResults(true);
    else setAreThereResults(labelExists(newValue));

    setSearchValue(newValue);
  };

  const handleCreateNew = async () => {
    const newBasicLabel: NewBasicLabel = {
      name: searchValue,
    };
    const response = await dispatch(createLabel(newBasicLabel));

    selectHandler.actions.addValue(response.payload);
    setAreThereResults(true);
  };

  const CustomNoOptionsMessage = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        No options
        <LoadingButton
          onClick={handleCreateNew}
          loading={isLoading}
          disabled={isDisabled}
          variant="contained"
          size="small"
          sx={{ marginTop: 1.3 }}
        >
          {t('global.createNew')}
        </LoadingButton>
      </Box>
    );
  };

  return (
    <Autocomplete
      multiple
      {...selectHandler.attributes}
      // @ts-ignore
      options={labels}
      getOptionLabel={(option) => option.name}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip label={option.name} {...getTagProps({ index })} />
        ))
      }
      size="small"
      disablePortal
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          className="contacts-smallInput"
          onChange={handleSearchChange}
        
        />
      )}
      noOptionsText={<CustomNoOptionsMessage />}
      sx={{
        width: '100%'
      }}
    />
  );
};

export default TagSelect;
