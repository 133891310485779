// useModal.ts
import { useState } from 'react';

interface UseEditModalReturn {
  isOpen: boolean;
  title: string;
  openModal: (title: string) => void;
  closeModal: () => void;
}

const useEditModal = (): UseEditModalReturn => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState<string>('');

  const openModal = (title: string) => {
    setTitle(title);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setTitle(''); // Clear the title when the modal closes
  };

  return { isOpen, title, openModal, closeModal };
};

export default useEditModal;
