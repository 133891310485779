import {
  createSlice,
  createAsyncThunk,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit';
import axios from 'axios';
// Utils
import initRequestData from '../../functions/initRequestData';
import contactsSliceService from './contactsSliceService';
import contactHelper from './helper/contactHelper';
// Types
import { ContactsSliceState } from './types/ContactsSliceState';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import type { RootState } from 'ReduxToolkit/rootReducer';
import type { IContact, IContactField } from '@trii/types/dist/Contacts';
import type { ContactTableData } from './types/ContactTableData';
import type { Pagination } from './types/Pagination';
import type { RequestStatus } from 'types/reduxTypes';
import type ContactFieldsFetchData from './types/ContactFieldsFetchData';
import type BusinessTableData from './types/BusinessTableData';
import { ILabel } from '@trii/types/dist/Conversations';
import { UpdateContactLabels } from './types/UpdateContactLabels';
import { DeleteContactAddressData } from './types/DeleteContactAddressData';
import { CheckContactAddressData } from './types/CheckContactAddressData';
import { SetContactAddressData } from './types/SetContactAddressData';
import { UpdateContactAddressData } from './types/UpdateContactAddressData';
import { resultContactAddressCheck } from '@trii/types/dist/ApiResponses';
import { Filter } from './types/Filter';
import getRequestConfig from 'ReduxToolkit/functions/getRequestConfig';
import { ChannelType } from '@trii/types/dist/Common/Channels';

const initialState: ContactsSliceState = {
  contactTableData: {
    contactos: [],
    business: [],
    contactosIds: [],
    checkedContactsIds: [],
    allChecked: false,
    paginacion: {
      format: 'IContact',
      total: 0,
      lastPage: 0,
      currentPage: 1,
      perPage: 10,
      order: 'ASC',
      orderColumn: 'Id',
      filter: [],
    },
  },
  contactClone: null,
  contactExport: {
    status: 'idle',
  },
  contactImport: {
    status: 'idle',
  },
  previousPath: '',
  status: {
    fetchContacts: 'idle',
    fetchBusiness: 'idle',
    labels: 'idle',
    clone: 'idle',
    contactsTableSet: false,
    businessTableSet: false,
    restore: 'idle',
    deletePermanently: 'idle',
  },
  contactFields: { fields: [], status: 'idle' },
  config: {
    itemsPerPage: 10,
    sortedColumn: {
      name: 'id',
      sort: 'asc',
    },
    filterPanel: { open: false, animate: false },
  },
  contactViewer: {
    contactData: null,
    status: {
      fetch: 'idle',
      updatePhoto: 'idle',
      deletePhoto: 'idle',
      update: 'idle',
      create: 'idle',
    },
  },
  labels: [],
  sectionMenuId: 0,
  section_details: true,
  section_notes: false,
  section_files: false,
  section_tasks: false,
  section_activities: false,
  section_diffusion: false,
  section_repair: false,
  section_debts: false,
  section_tickets: false,
  showBusinessSearchModal: false,
};

export const fetchContactsTableData = createAsyncThunk<
  ContactTableData,
  Pagination,
  { state: RootState }
>('contacts/fetchContactsTableData', async (paginationFetchData, { dispatch }) => {
  const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
    .payload as InitRequestDataReturn;

  const contactTableData = await contactsSliceService.fetchContactsTableData(
    jwtToken,
    URL_CONTACTS,
    paginationFetchData
  );

  return contactTableData;
});
export const fetchBusinessTableData = createAsyncThunk<
  BusinessTableData,
  Pagination,
  { state: RootState }
>('contacts/fetchBusinessTableData', async (paginationFetchData, { dispatch }) => {
  const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
    .payload as InitRequestDataReturn;

  const businessTableData = await contactsSliceService.fetchBusinessTableData(
    jwtToken,
    URL_CONTACTS,
    paginationFetchData
  );

  return businessTableData;
});
export const fetchContact = createAsyncThunk(
  'contacts/fetchContact',
  async (id: string, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await contactsSliceService.fetchContact(
      jwtToken,
      URL_CONTACTS,
      id
    );

    return response;
  }
);
export const updateUserPhoto = createAsyncThunk(
  'User/updateUserPhoto',
  async (file: File, { dispatch, getState }) => {
    const { jwtToken, URL_MEDIA } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const state = getState() as RootState;
    const contactId = state.Contacts.contactViewer.contactData.id;
    const fd = new FormData();

    fd.append('file', file, file.name);

    const response = await contactsSliceService.uploadPhoto(
      jwtToken,
      URL_MEDIA,
      fd,
      contactId
    );

    return response;
  }
);
export const deleteUserPhoto = createAsyncThunk(
  'User/deleteUserPhoto',
  async (id: string, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    await contactsSliceService.deleteUserPhoto(jwtToken, URL_CONTACTS, id);
  }
);
export const exportContacts = createAsyncThunk(
  'contacts/exportContacts',
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    await contactsSliceService.exportContacts(jwtToken, URL_CONTACTS);
  }
);
export const importContacts = createAsyncThunk(
  'Contacts/importContacts',

  async (data: FormData, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const config = getRequestConfig.contentTypeFormData(jwtToken);

    const response = await axios.put(`${URL_CONTACTS}/Import`, data, config);

    return response.data;
  }
);
export const fetchContactFields = createAsyncThunk<
  IContactField[],
  ContactFieldsFetchData,
  { state: RootState }
>('contacts/fetchContactFields', async (fetchData, { dispatch }) => {
  const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
    .payload as InitRequestDataReturn;
  const { filterBy, fetchFor } = fetchData;

  const response = await contactsSliceService.fetchFields(
    jwtToken,
    URL_CONTACTS,
    filterBy,
    fetchFor
  );

  return response;
});

export const createContact = createAsyncThunk<any, IContact, { state: RootState }>(
  'contacts/createContact',
  async (contact, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const JSONdata = JSON.stringify(contact);

    const response = await contactsSliceService.createContact(
      jwtToken,
      URL_CONTACTS,
      JSONdata
    );

    return response;
  }
);
export const updateContact = createAsyncThunk(
  'contacts/updateContact',
  async (contactInfo: any, { dispatch, getState }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const state = getState() as RootState;
    const contactId = state.Contacts.contactViewer.contactData.id;

    const JSONdata = JSON.stringify(contactInfo);

    const response = await contactsSliceService.updateContact(
      jwtToken,
      URL_CONTACTS,
      JSONdata,
      contactId
    );

    return response;
  }
);

export const fetchLabels = createAsyncThunk(
  'label/fetchLabels',
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await contactsSliceService.fetchLabels(jwtToken, URL_CONTACTS);

    return response;
  }
);

export const deleteContacts = createAsyncThunk(
  'contacts/deleteContacts',
  async (ids: string[], { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    await contactsSliceService.deleteContacts(jwtToken, URL_CONTACTS, ids);
  }
);

export const updateContactLabels = createAsyncThunk(
  'contacts/updateContactLabels',
  async (data: UpdateContactLabels, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    await contactsSliceService.updateContactLabels(jwtToken, URL_CONTACTS, data);
  }
);

export const fetchCloneContact = createAsyncThunk(
  'contacts/fetchCloneContact',
  async (id: string, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await contactsSliceService.fetchCloneContact(
      jwtToken,
      URL_CONTACTS,
      id
    );

    return response;
  }
);

export const deleteContact = createAsyncThunk(
  'contacts/deleteContact',
  async (id: string, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    await contactsSliceService.deleteContact(jwtToken, URL_CONTACTS, id);
  }
);

export const deleteContactAddress = createAsyncThunk(
  'contacts/deleteContactAddress',
  async (deleteData: DeleteContactAddressData, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await contactsSliceService.deleteContactAddress(
      jwtToken,
      URL_CONTACTS,
      deleteData
    );

    return response;
  }
);

export const checkContactAddress = createAsyncThunk<
  resultContactAddressCheck,
  CheckContactAddressData
>('contacts/checkContactAddress', async (checkData, { dispatch }) => {
  const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
    .payload as InitRequestDataReturn;

  const response = await contactsSliceService.checkContactAddress(
    jwtToken,
    URL_CONTACTS,
    checkData
  );

  return response;
});

export const setContactAddress = createAsyncThunk(
  'contacts/setContactAddress',
  async (setData: SetContactAddressData, { dispatch }) => {
    const { requestData, addressId } = setData;
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const newAddress = await contactsSliceService.setContactAddress(
      jwtToken,
      URL_CONTACTS,
      requestData
    );
    return { newAddress, addressId };
  }
);

export const updateContactAddress = createAsyncThunk(
  'contacts/updateContactAddress',
  async (updateData: UpdateContactAddressData, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await contactsSliceService.updateContactAddress(
      jwtToken,
      URL_CONTACTS,
      updateData
    );
    return response;
  }
);

export const restoreContacts = createAsyncThunk(
  'contacts/restoreContacts',
  async (ids: string[], { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    await contactsSliceService.restoreContacts(jwtToken, URL_CONTACTS, ids);
  }
);

export const deleteContactsPermanently = createAsyncThunk(
  'contacts/deleteContactsPermanently',
  async (ids: string[], { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    await contactsSliceService.deleteContactsPermanently(
      jwtToken,
      URL_CONTACTS,
      ids
    );
  }
);

const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    setPreviousPath: (state, action: PayloadAction<string>) => {
      state.previousPath = action.payload;
    },
    setContactTableSetStatus(state, action: PayloadAction<boolean>) {
      state.status.contactsTableSet = action.payload;
    },
    setBusinessTableSetStatus(state, action: PayloadAction<boolean>) {
      state.status.businessTableSet = action.payload;
    },
    setContactCreateStatus(state, action: PayloadAction<RequestStatus>) {
      state.contactViewer.status.create = action.payload;
    },
    clearStatus(state) {
      state.status.fetchBusiness = 'idle';
      state.status.fetchContacts = 'idle';
      state.status.labels = 'idle';
      state.status.clone = 'idle';
    },
    resetContactsTableDataStatus: (state) => {
      state.status.fetchContacts = 'idle';
    },
    clearTableData(state) {
      state.contactTableData = {
        contactos: [],
        business: [],
        checkedContactsIds: [],
        allChecked: false,
        contactosIds: [],
        paginacion: {
          format: 'IContact',
          total: 0,
          lastPage: 0,
          currentPage: 1,
          perPage: 10,
          order: 'ASC',
          orderColumn: 'Id',
          filter: [],
        },
      };
    },
    checkContact(state, action) {
      const contactId = action.payload;
      const contact = state.contactTableData.contactos.find(
        (contact) => contact.id === contactId
      );

      if (contact.checked) {
        state.contactTableData.checkedContactsIds = [
          ...state.contactTableData.checkedContactsIds.filter(
            (checkedContactId) => checkedContactId !== contact.id
          ),
        ];
      } else {
        state.contactTableData.checkedContactsIds = [
          ...state.contactTableData.checkedContactsIds,
          contact.id,
        ];
      }

      contact.checked = !contact.checked;
    },
    checkBusiness(state, action) {
      const businessId = action.payload;
      const business = state.contactTableData.business.find(
        (business) => business.id === businessId
      );

      if (business.checked) {
        state.contactTableData.checkedContactsIds = [
          ...state.contactTableData.checkedContactsIds.filter(
            (checkedContactId) => checkedContactId !== business.id
          ),
        ];
      } else {
        state.contactTableData.checkedContactsIds = [
          ...state.contactTableData.checkedContactsIds,
          business.id,
        ];
      }

      business.checked = !business.checked;
    },
    checkPageContacts(state, action: PayloadAction<boolean>) {
      const checked = action.payload;
      const { checkedContactsIds } = state.contactTableData;
      const currentPageContactsIds = state.contactTableData.contactos.map(
        (contact) => contact.id
      );

      const checkedContacts = state.contactTableData.contactos.map((contact) => {
        contact.checked = checked;

        return contact;
      });

      if (checked) {
        state.contactTableData.checkedContactsIds = [
          ...checkedContactsIds.filter(
            (checkedContactId) => !currentPageContactsIds.includes(checkedContactId)
          ),
          ...currentPageContactsIds,
        ];
      } else {
        state.contactTableData.checkedContactsIds = [
          ...checkedContactsIds.filter(
            (checkedContactId) => !currentPageContactsIds.includes(checkedContactId)
          ),
        ];
      }

      state.contactTableData.contactos = checkedContacts;
    },
    checkPageBusiness(state, action: PayloadAction<boolean>) {
      const checked = action.payload;
      const { checkedContactsIds } = state.contactTableData;
      const currentPageContactsIds = state.contactTableData.business.map(
        (business) => business.id
      );

      const checkedContacts = state.contactTableData.business.map((business) => {
        business.checked = checked;

        return business;
      });

      if (checked) {
        state.contactTableData.checkedContactsIds = [
          ...checkedContactsIds.filter(
            (checkedContactId) => !currentPageContactsIds.includes(checkedContactId)
          ),
          ...currentPageContactsIds,
        ];
      } else {
        state.contactTableData.checkedContactsIds = [
          ...checkedContactsIds.filter(
            (checkedContactId) => !currentPageContactsIds.includes(checkedContactId)
          ),
        ];
      }

      state.contactTableData.business = checkedContacts;
    },
    checkAllContacts(state, action: PayloadAction<boolean>) {
      const checked = action.payload;
      const allContactsChecked = state.contactTableData.contactos.map((contact) => {
        contact.checked = checked;
        return contact;
      });
      const { contactosIds } = state.contactTableData;

      state.contactTableData.contactos = allContactsChecked;
      state.contactTableData.checkedContactsIds = checked
        ? [...state.contactTableData.contactosIds]
        : [];
    },
    checkAllBusiness(state, action: PayloadAction<boolean>) {
      const checked = action.payload;

      state.contactTableData.business = state.contactTableData.business.map(
        (business) => {
          business.checked = checked;

          return business;
        }
      );

      if (checked) {
        state.contactTableData.checkedContactsIds = [
          ...state.contactTableData.contactosIds,
        ];
      } else {
        state.contactTableData.checkedContactsIds = [];
      }
    },
    changeItemsPerPage(state, action) {
      const itemsPerPage = action.payload;

      state.contactTableData.paginacion.perPage = itemsPerPage;
    },
    changeContactOrder(state, action) {
      const { order, orderColumn } = action.payload;

      state.contactTableData.paginacion.order = order;
      state.contactTableData.paginacion.orderColumn = orderColumn;
    },
    setPagination(state, action: PayloadAction<Pagination>) {
      const pagination = action.payload;

      state.contactTableData.paginacion = pagination;
    },
    setPaginationFilter(state, action: PayloadAction<Filter[]>) {
      const filter = action.payload;

      state.contactTableData.paginacion.filter = filter;
    },
    changeColumnVisibility(state, action) {
      const { columnName, visible } = action.payload;
      const column = state.contactFields.fields.find(
        (column) => column.name === columnName
      );

      if (column) {
        column.visible = visible;
      }
    },
    updateContactFields(state, action) {
      const newContactFields = action.payload;

      state.contactFields.fields = newContactFields;
    },
    changeCurrentPage(state, action) {
      const newCurrentPage = action.payload;

      state.contactTableData.paginacion.currentPage = newCurrentPage;
    },
    changeFilterPanelVisibility(state, action) {
      const isVisible = action.payload;
      state.config.filterPanel.open = isVisible;
    },
    changeFilterPanelAnimate(state, action) {
      const animate = action.payload;
      state.config.filterPanel.animate = animate;
    },
    changeContactExportStatus(state, action) {
      const status = action.payload;
      state.contactExport.status = status;
    },
    setContactViewerData(state, action: PayloadAction<IContact>) {
      const contactData = action.payload;
      state.contactViewer.contactData = contactData;
    },
    setContactViewerFetchStatus(state, action: PayloadAction<RequestStatus>) {
      const status = action.payload;
      state.contactViewer.status.fetch = status;
    },
    updateContactEdit(state, action) {
      const newContactEdit = {
        ...state.contactViewer.contactData,
        ...action.payload,
      };

      state.contactViewer.contactData = newContactEdit;
    },
    sectionMenuOpenCard(state, action) {
      const sectionId = action.payload;
      state.sectionMenuId = sectionId;
    },
    sectionMenuOpenDetails(state, action) {
      const sectionId = action.payload;
      state.section_details = sectionId;
    },
    sectionMenuOpenNotes(state, action) {
      const sectionId = action.payload;
      state.section_notes = sectionId;
    },
    sectionMenuOpenFiles(state, action) {
      const sectionId = action.payload;
      state.section_files = sectionId;
    },
    sectionMenuOpenTasks(state, action) {
      const sectionId = action.payload;
      state.section_tasks = sectionId;
    },
    sectionMenuOpenActivities(state, action) {
      const sectionId = action.payload;
      state.section_activities = sectionId;
    },
    sectionMenuOpenDiffusion(state, action) {
      const sectionId = action.payload;
      state.section_diffusion = sectionId;
    },
    sectionMenuOpenRepair(state, action) {
      const sectionId = action.payload;
      state.section_repair = sectionId;
    },
    sectionMenuOpenDebts(state, action) {
      const sectionId = action.payload;
      state.section_debts = sectionId;
    },
    sectionMenuOpenTickets(state, action) {
      const sectionId = action.payload;
      state.section_tickets = sectionId;
    },
    setShowBusinessSearchModal(state, action) {
      const isOpen = action.payload;
      console.log('isOpen', isOpen);
      state.showBusinessSearchModal = isOpen;
    },
    updateDeleteContacts(state, action) {
      const ids = action.payload;
      const { contactos } = state.contactTableData;
      const { business } = state.contactTableData;

      state.contactTableData.contactos = contactos.filter(
        (contact) => !ids.includes(contact.id)
      );
      state.contactTableData.business = business.filter(
        (contact) => !ids.includes(contact.id)
      );
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchContactsTableData.pending, (state) => {
        state.status.fetchContacts = 'loading';
      })
      .addCase(fetchContactsTableData.fulfilled, (state, action) => {
        const newContactTableData = action.payload;
        const { contactos, paginacion, contactosIds } = newContactTableData;
        const { checkedContactsIds } = state.contactTableData;
        const tableContactsWithCheckboxes = contactHelper.addContactCheckboxes(
          contactos,
          checkedContactsIds
        );

        state.contactTableData.contactosIds = contactosIds;
        state.contactTableData.contactos = tableContactsWithCheckboxes;
        state.contactTableData.paginacion.total = paginacion.total;
        state.contactTableData.paginacion.lastPage = paginacion.lastPage;
        state.contactTableData.paginacion.currentPage = paginacion.currentPage;
        state.status.fetchContacts = 'succeeded';
      })
      .addCase(fetchContactsTableData.rejected, (state, action) => {
        state.status.fetchContacts = 'idle';
      })
      .addCase(fetchBusinessTableData.pending, (state) => {
        state.status.fetchBusiness = 'loading';
      })
      .addCase(fetchBusinessTableData.fulfilled, (state, action) => {
        const newBusinessTableData = action.payload;
        const { contactos, paginacion, contactosIds } = newBusinessTableData;
        const tableContactsWithCheckboxes = contactHelper.addBusinessCheckboxes(
          contactos,
          state.contactTableData.checkedContactsIds
        );
        state.contactTableData.business = tableContactsWithCheckboxes;
        state.contactTableData.contactosIds = contactosIds;
        state.contactTableData.paginacion.total = paginacion.total;
        state.contactTableData.paginacion.lastPage = paginacion.lastPage;
        state.contactTableData.paginacion.currentPage = paginacion.currentPage;
        state.status.fetchBusiness = 'succeeded';
      })
      .addCase(fetchContactFields.pending, (state) => {
        state.contactFields.status = 'loading';
      })
      .addCase(fetchContactFields.fulfilled, (state, action) => {
        console.log('fetchContactFields.fulfilled', action.payload);
        const newContactFields = contactHelper.fieldsToTableFields(action.payload);

        state.contactFields.status = 'succeeded';
        state.contactFields.fields = newContactFields;
      })
      .addCase(exportContacts.fulfilled, (state) => {
        state.contactExport.status = 'succeeded';
      })
      .addCase(exportContacts.pending, (state) => {
        state.contactExport.status = 'loading';
      })
      .addCase(importContacts.fulfilled, (state) => {
        state.contactImport.status = 'succeeded';
      })
      .addCase(importContacts.pending, (state) => {
        state.contactImport.status = 'loading';
      })
      .addCase(fetchContact.pending, (state) => {
        state.contactViewer.status.fetch = 'loading';
      })
      .addCase(fetchContact.fulfilled, (state, action: PayloadAction<IContact>) => {
        const contact = action.payload;
        state.contactViewer.status.fetch = 'succeeded';

        state.contactViewer.contactData = contact;
      })
      .addCase(createContact.fulfilled, (state, action) => {
        state.contactViewer.status.create = 'succeeded';
      })
      .addCase(createContact.pending, (state) => {
        state.contactViewer.status.create = 'loading';
      })
      .addCase(createContact.rejected, (state, action) => {
        state.contactViewer.status.create = { error: action.error.message };
      })
      .addCase(updateContact.fulfilled, (state, action) => {
        state.contactViewer.status.update = 'succeeded';
        // console.log('Setting contact data to', action.payload);
        state.contactViewer.contactData = action.payload;
      })
      .addCase(updateContact.rejected, (state, action) => {
        console.log(action.error.message);
      })
      .addCase(updateContact.pending, (state) => {
        state.contactViewer.status.update = 'loading';
      })
      .addCase(updateUserPhoto.fulfilled, (state, action: PayloadAction<string>) => {
        state.contactViewer.status.updatePhoto = 'succeeded';
        if (state.contactViewer.contactData)
          state.contactViewer.contactData.imageUrl = action.payload;
      })
      .addCase(updateUserPhoto.pending, (state) => {
        state.contactViewer.status.updatePhoto = 'loading';
      })
      .addCase(deleteUserPhoto.fulfilled, (state) => {
        state.contactViewer.status.deletePhoto = 'succeeded';
        state.contactViewer.contactData.imageUrl = '';
      })
      .addCase(deleteUserPhoto.pending, (state) => {
        state.contactViewer.status.deletePhoto = 'loading';
      })
      .addCase(fetchLabels.fulfilled, (state, action: PayloadAction<ILabel[]>) => {
        state.status.labels = 'succeeded';
        state.labels = action.payload;
      })
      .addCase(fetchLabels.pending, (state) => {
        state.status.labels = 'loading';
      })
      .addCase(updateContactLabels.fulfilled, (state, action) => {
        state.status.labels = 'succeeded';
      })
      .addCase(updateContactLabels.pending, (state) => {
        state.status.labels = 'loading';
      })
      .addCase(
        fetchCloneContact.fulfilled,
        (state, action: PayloadAction<IContact>) => {
          state.status.clone = 'succeeded';
          state.contactClone = action.payload;
        }
      )
      .addCase(fetchCloneContact.pending, (state) => {
        state.status.clone = 'loading';
      })
      .addCase(fetchCloneContact.rejected, (state, action) => {
        state.status.clone = { error: action.error.message };
      })
      .addCase(restoreContacts.fulfilled, (state) => {
        state.status.restore = 'succeeded';
      })
      .addCase(restoreContacts.pending, (state) => {
        state.status.restore = 'loading';
      })
      .addCase(deleteContactsPermanently.fulfilled, (state) => {
        state.status.deletePermanently = 'succeeded';
      })
      .addCase(deleteContactsPermanently.pending, (state) => {
        state.status.deletePermanently = 'loading';
      })
      .addCase(setContactAddress.fulfilled, (state, action) => {
        const { newAddress } = action.payload;

        if (newAddress.channelType === ChannelType.EMAIL) {
          state.contactViewer.contactData.emails.push(newAddress);
        } else if (newAddress.channelType === ChannelType.PHONE) {
          state.contactViewer.contactData.phones.push(newAddress);
        } else if (newAddress.channelType === ChannelType.WHATSAPP) {
          state.contactViewer.contactData.ims_whatsapp.push(newAddress);
        }
        else if (newAddress.channelType === ChannelType.RCS) {
          state.contactViewer.contactData.ims_rcs.push(newAddress);
        }
        else if (newAddress.channelType === ChannelType.WEBCHAT) {
          state.contactViewer.contactData.ims_webchat.push(newAddress);
        }
      })
      .addCase(deleteContactAddress.fulfilled, (state, action) => {
        const { phones, ims_instagram, ims_whatsapp, emails, ims_rcs, ims_webchat } = action.payload;
        state.contactViewer.contactData.phones = phones;
        state.contactViewer.contactData.ims_instagram = ims_instagram;
        state.contactViewer.contactData.ims_whatsapp = ims_whatsapp;
        state.contactViewer.contactData.emails = emails;
        state.contactViewer.contactData.ims_rcs = ims_rcs;
        state.contactViewer.contactData.ims_webchat = ims_webchat;

      })
      .addCase(updateContactAddress.fulfilled, (state, action) => {
        const updatedAddress = action.payload;
        if (updatedAddress.channelType === ChannelType.EMAIL) {
          state.contactViewer.contactData.emails =
            state.contactViewer.contactData.emails.map((email) => {
              if (email.id === updatedAddress.id) {
                return updatedAddress;
              }
              return email;
            });
        } else if (updatedAddress.channelType === ChannelType.PHONE) {
          state.contactViewer.contactData.phones =
            state.contactViewer.contactData.phones.map((phone) => {
              if (phone.id === updatedAddress.id) {
                return updatedAddress;
              }
              return phone;
            });
        } else if (updatedAddress.channelType === ChannelType.WHATSAPP) {
          state.contactViewer.contactData.ims_whatsapp =
            state.contactViewer.contactData.ims_whatsapp.map((whatsapp) => {
              if (whatsapp.id === updatedAddress.id) {
                return updatedAddress;
              }
              return whatsapp;
            })}
            else if (updatedAddress.channelType === ChannelType.RCS) {
              state.contactViewer.contactData.ims_rcs =
                state.contactViewer.contactData.ims_rcs.map((ims) => {
                  if (ims.id === updatedAddress.id) {
                    return updatedAddress;
                  }
                  return ims;
                });
        } else if (updatedAddress.channelType === ChannelType.INSTAGRAM) {
          state.contactViewer.contactData.ims_instagram =
            state.contactViewer.contactData.ims_instagram.map((instagram) => {
              if (instagram.id === updatedAddress.id) {
                return updatedAddress;
              }
              return instagram;
            });
        }
        else if (updatedAddress.channelType === ChannelType.WEBCHAT) {
          state.contactViewer.contactData.ims_webchat =
            state.contactViewer.contactData.ims_webchat.map((webchat) => {
              if (webchat.id === updatedAddress.id) {
                return updatedAddress;
              }
              return webchat;
            });
        }
        else if (updatedAddress.channelType === ChannelType.FACEBOOK) {
          state.contactViewer.contactData.ims_facebook =
            state.contactViewer.contactData.ims_facebook.map((facebook) => {
              if (facebook.id === updatedAddress.id) {
                return updatedAddress;
              }
              return facebook;
            });
        }
        else if (updatedAddress.channelType === ChannelType.MERCADOLIBRE) {
          state.contactViewer.contactData.ims_mercadolibre =
            state.contactViewer.contactData.ims_mercadolibre.map((mercadolibre) => {
              if (mercadolibre.id === updatedAddress.id) {
                return updatedAddress;
              }
              return mercadolibre;
            });
        }
      });
  },
});

// Selectors & Getters
const contactsState = (state: RootState) => state.Contacts;
export const selectRestoreContactsStatus = createSelector(
  contactsState,
  (state) => state.status.restore
);
export const selectDeleteContactsPermanentlyStatus = createSelector(
  contactsState,
  (state) => state.status.deletePermanently
);
export const getContactsAmount = createSelector(
  contactsState,
  (state) => state.contactTableData.contactos.length
);
export const getCheckedContactsAmount = createSelector(
  contactsState,
  (state) => state.contactTableData.checkedContactsIds.length
);
export const getCheckedContactsIds = createSelector(
  contactsState,
  (state) => state.contactTableData.checkedContactsIds
);
export const getAllContactsAmount = createSelector(
  contactsState,
  (state) => state.contactTableData.paginacion.total
);
export const getAllContactIds = createSelector(
  contactsState,
  (state) => state.contactTableData.contactosIds
);
export const isContactPageChecked = (state: RootState) => {
  const { checkedContactsIds } = state.Contacts.contactTableData;
  const contactsPerPage = state.Contacts.contactTableData.paginacion.perPage;
  const currentPageContactsIds = state.Contacts.contactTableData.contactos.map(
    (contact) => contact.id
  );
  const checkedContacts = currentPageContactsIds.filter((contactId) =>
    checkedContactsIds.includes(contactId)
  );

  if (checkedContacts.length === 0) {
    return 'none';
  } else if (
    checkedContacts.length > 0 &&
    checkedContacts.length < contactsPerPage
  ) {
    return 'some';
  }

  return 'all';
};
export const isBusinessPageChecked = (state: RootState) => {
  const { checkedContactsIds } = state.Contacts.contactTableData;
  const businessPerPage = state.Contacts.contactTableData.paginacion.perPage;
  const currentPageBusinessIds = state.Contacts.contactTableData.business.map(
    (business) => business.id
  );
  const checkedBusiness = currentPageBusinessIds.filter((contactId) =>
    checkedContactsIds.includes(contactId)
  );

  if (checkedBusiness.length === 0) {
    return 'none';
  } else if (
    checkedBusiness.length > 0 &&
    checkedBusiness.length < businessPerPage
  ) {
    return 'some';
  }

  return 'all';
};
export const selectContactsFetchStatus = createSelector(
  contactsState,
  (state) => state.status.fetchContacts
);
export const selectBusinessFetchStatus = createSelector(
  contactsState,
  (state) => state.status.fetchBusiness
);
export const getFetchContactFieldsStatus = createSelector(
  contactsState,
  (state) => state.contactFields.status
);
export const getAreAllContactsChecked = createSelector(
  contactsState,
  (state) =>
    state.contactTableData.contactosIds.length ===
    state.contactTableData.checkedContactsIds.length
);
export const selectContactsTableData = createSelector(
  contactsState,
  (state) => state.contactTableData
);
export const selectContacts = createSelector(
  contactsState,
  (state) => state.contactTableData.contactos
);
export const selectBusiness = createSelector(
  contactsState,
  (state) => state.contactTableData.business
);
export const selectContactById = (state: RootState, contactId: string) => {
  const contact = state.Contacts.contactTableData.contactos.find(
    (contact) => contact.id === contactId
  );
  return contact;
};
export const selectPagination = createSelector(
  contactsState,
  (state) => state.contactTableData.paginacion
);
export const getFilter = (state: RootState) =>
  state.Contacts.contactTableData.paginacion.filter;
export const selectContactFields = createSelector(
  contactsState,
  (state) => state.contactFields.fields
);
export const selectCustomContactFields = createSelector(contactsState, (state) =>
  state.contactFields.fields
    .filter((field) => !field.isDefault)
    .sort((a, b) => a.order - b.order)
);

export const getContactFieldsFetchStatus = createSelector(
  contactsState,
  (state) => state.contactFields.status
);
export const getContactCreateStatus = createSelector(
  contactsState,
  (state) => state.contactViewer.status.create
);
export const selectFilterPanelVisibility = (state: RootState) =>
  state.Contacts.config.filterPanel.open;
export const selectFilterPanelAnimate = (state: RootState) =>
  state.Contacts.config.filterPanel.animate;
export const selectContactExportStatus = createSelector(
  contactsState,
  (state) => state.contactExport.status
);
export const selectContactData = createSelector(
  contactsState,
  (state) => state.contactViewer.contactData
);
export const selectContactFetchStatus = createSelector(
  contactsState,
  (state) => state.contactViewer.status.fetch
);
export const selectContactUploadPhotoStatus = createSelector(
  contactsState,
  (state) => state.contactViewer.status.updatePhoto
);
export const getContactPropertyValue = (state: RootState, propertyName: string) => {
  const contactProperties = state.Contacts.contactViewer.contactData.properties;
  const propertyValue = contactProperties.find(
    (property) => property.nameKey === propertyName
  );

  return propertyValue;
};
export const selectAllLabels = createSelector(
  contactsState,
  (state) => state.labels
);
export const getLabelsFetchStatus = createSelector(
  contactsState,
  (state) => state.status.labels
);
export const selectCloneContact = createSelector(
  contactsState,
  (state) => state.contactClone
);
export const getCloneContactFetchStatus = createSelector(
  contactsState,
  (state) => state.status.clone
);

export const getSectionMenuId = (state) => state.Contacts.sectionMenuId;
export const getSectionDetails = (state) => state.Contacts.section_details;
export const getSectionNotes = (state) => state.Contacts.section_notes;
export const getSectionFiles = (state) => state.Contacts.section_files;
export const getSectionTasks = (state) => state.Contacts.section_tasks;
export const getSectionActivities = (state) => state.Contacts.section_activities;
export const getSectionDiffusion = (state) => state.Contacts.section_diffusion;
export const getSectionRepair = (state) => state.Contacts.section_repair;
export const getSectionDebts = (state) => state.Contacts.section_debts;
export const getSectionTickets = (state) => state.Contacts.section_tickets;
export const getShowBusinessSearchModal = (state) =>
  state.Contacts.showBusinessSearchModal;

export const getContactTableSetStatus = (state) =>
  state.Contacts.status.contactsTableSet;
export const getBusinessTableSetStatus = (state) =>
  state.Contacts.status.businessTableSet;
export const getPreviousPath = (state: RootState) => state.Contacts.previousPath;

// Actions
export const {
  setPreviousPath,
  setContactTableSetStatus,
  setBusinessTableSetStatus,
  clearStatus,
  clearTableData,
  checkContact,
  checkBusiness,
  checkPageContacts,
  checkPageBusiness,
  checkAllContacts,
  checkAllBusiness,
  changeContactOrder,
  changeItemsPerPage,
  changeColumnVisibility,
  updateContactFields,
  changeCurrentPage,
  changeFilterPanelVisibility,
  changeFilterPanelAnimate,
  changeContactExportStatus,
  setPagination,
  setPaginationFilter,
  updateContactEdit,
  sectionMenuOpenCard,
  sectionMenuOpenDetails,
  sectionMenuOpenNotes,
  sectionMenuOpenTasks,
  sectionMenuOpenFiles,
  sectionMenuOpenActivities,
  sectionMenuOpenDiffusion,
  sectionMenuOpenRepair,
  sectionMenuOpenDebts,
  sectionMenuOpenTickets,
  setContactViewerData,
  setContactViewerFetchStatus,
  setContactCreateStatus,
  updateDeleteContacts,
  resetContactsTableDataStatus,
  setShowBusinessSearchModal,
} = contactsSlice.actions;

export default contactsSlice.reducer;
