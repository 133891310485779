import { styled } from '@mui/system';
import { Box, Typography } from '@mui/material';
import { ContactMethodItem, ContactMethodPhoneItem } from './components';
import { IContactAddress } from '@trii/types/dist/Contacts';

interface Props {
  title: string;
  methodItems: IContactAddress[];
  isPhone?: boolean;
}

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1, 0, 2, 0),
  width: '100%',
  gap: theme.spacing(1),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  letterSpacing: -0.5,
  fontSize: 14,
  fontWeight: 600,
}));

const MethodItemsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  width: '100%',
}));

const ContactMethod = ({ title, methodItems, isPhone }: Props) => {
  return (
    <StyledBox>
      <StyledTypography>{title}</StyledTypography>
      <MethodItemsBox>
        {methodItems.map((item, index) => {
          if (isPhone) {
            return <ContactMethodPhoneItem key={index} item={item} />;
          }
          return <ContactMethodItem key={index} item={item} />;
        })}
      </MethodItemsBox>
    </StyledBox>
  );
};

export default ContactMethod;
