import { useContext } from 'react';
// Translations
import { useTranslation } from "react-i18next";
// Icons
import { Close } from "@mui/icons-material";
// Components/ui
import {
  Box,
  Divider,
  IconButton,
  Modal,
  Slide,
  Typography,
} from "@mui/material"
// Context
import { ContactBusinessTableContext } from 'components/ContactsBusinessTable/context/ContactBusinessTableContext';
// Components
import {
  ChangeOwner,
  DeleteContact,
  Label,
} from './components';

const ModalList = () => {
  const {
    openModalList,
    setOpenModalList,
    modalTitle,
  } = useContext(ContactBusinessTableContext);
  const { t } = useTranslation();

  const handleClose = () => {
    setOpenModalList(false)
  }

  return (
    <Modal
      open={openModalList}
      onClose={() => handleClose()}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'start',
        marginTop: '5rem',
       
      }}
    >
      <Slide
        direction="down"
        in={openModalList}
        mountOnEnter
        unmountOnExit
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            maxWidth: '30rem',
            maxHeight: '40rem',
            minHeight: '10rem',
            overflow: 'auto',
            borderRadius: 2,
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
       
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '1em',
            }}
          >
            <Typography
              variant="h6"
              color={(theme) => theme.palette.text.primary}

            >
              {modalTitle}
            </Typography>
            <IconButton
              onClick={handleClose}
            >
              <Close
                sx={{
                  color: (theme) => theme.palette.text.disabled,
                }}
              />
            </IconButton>
          </Box>
          <Divider />
          {
            (
              modalTitle === t('contactsView.contactBusinessTable.addLabel') ||
              modalTitle === t('contactsView.contactBusinessTable.removeLabel')
            ) && (
              <Label />
            )
          }
          {
            modalTitle === t('contactsView.contactBusinessTable.selectUser') && (
              <ChangeOwner />
            )
          }
          {
            modalTitle === t('contactsView.contactBusinessTable.confirmDeletion') && (
              <DeleteContact />
            )
          }
        </Box>
      </Slide>
    </Modal>
  )
}

export default ModalList