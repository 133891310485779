// Types
import { IContactAddress } from '@trii/types/dist/Contacts';
// Components/ui
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  item: IContactAddress;
};

const ContactMethodItem = ({ item }: Props) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Box sx={{ width: '40%' }}>{item.address}</Box>
      <Box sx={{ width: '40%' }}>
        {t('global.note')}: {item.note}
      </Box>
    </Box>
  );
};

export default ContactMethodItem;
