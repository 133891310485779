import { ChangeEvent, useEffect, useState } from 'react';
// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  selectCustomContactFields,
  deleteContactAddress,
} from 'ReduxToolkit/features/contactsSlice/contactsSlice';
// Types
import {
  IContact,
  Property,
  IContactAddress,
  IContactInfo,
} from '@trii/types/dist/Contacts';
import UseEditContact from './types/UseEditContact';
import { ItemWithId } from 'hooks/useMultipleSelect';
import { ChannelType } from '@trii/types/dist/Common/Channels';
// Hooks
import useField from 'hooks/useField';
import useImage from 'hooks/useImage';
import useSelect from 'hooks/useSelect';
import { useMultipleSelect } from 'hooks/useMultipleSelect';
// Utils
import contactHelper from 'utils/contactHelper';
import editContactHelper from './utils/editContactHelper';
import dayjs, { Dayjs } from 'dayjs';
import { SimplifiedBusiness } from 'components/ContactCreateEditAppBody/components/DetailsSection/components/BusinessSelect/types';

const useEditContact = (contact: IContact | null): UseEditContact => {
  const dispatch = useAppDispatch();

  const { getEditedField } = editContactHelper;

  const [isDeletingAddress, setIsDeletingAddress] = useState<false | string>(false);
  const [customProperties, setCustomProperties] = useState<Property[]>([]);
  const customContactFields = useSelector(selectCustomContactFields);

  const contactFirstname = useField('text', contact?.firstName || '');
  const contactLastname = useField('text', contact?.lastName || '');
  const contactImage = useImage(contact?.imageUrl);
  const [birthDate, setBirthDate] = useState<Dayjs | null>(
    contact?.birthDate ? dayjs(contact.birthDate) : null
  );
  // Contact information
  const [imsFacebooks, setImsFacebooks] = useState(contact?.ims_facebook || []);
  const [imsWebchats, setImsWebchats] = useState(contact?.ims_webchat || []);
  const [imsInstagrams, setImsInstagrams] = useState(contact?.ims_instagram || []);
  const [imsWhatsapp, setImsWhatsApp] = useState(contact?.ims_whatsapp || []);
  const [emails, setEmails] = useState(contact?.emails || []);
  const [phoneNumbers, setPhoneNumbers] = useState(contact?.phones || []);
  const [imsRCS, setImsRCS] = useState(contact?.ims_rcs || []);
  //
  const labelMultipleSelect = useMultipleSelect({
    initialState: contact?.tags as ItemWithId[],
  });
  const [selectedBusiness, setSelectedBusiness] = useState<SimplifiedBusiness[]>([]);
  const contactPropietary = useSelect(contact?.owner);
  const contactListSelectState = useSelect(contact?.listId);
  const homeAddress = useField('text', contact?.address1?.street);
  const secondaryHomeAddress = useField('text', contact?.address2?.street);
  const city = useField('text', contact?.address1?.city);
  const secondaryCity = useField('text', contact?.address2?.city);
  const state = useField('text', contact?.address1?.state);
  const secondaryState = useField('text', contact?.address2?.state);
  const postalCode = useField('text', contact?.address1?.zipcode);
  const secondaryPostalCode = useField('text', contact?.address2?.zipcode);
  const country = useField('text', contact?.address1?.country);
  const secondaryCountry = useField('text', contact?.address2?.country);

  // Actions
  const getEditedContact = () => {
    const modifiedTags = labelMultipleSelect.attributes.value.map((tag) => ({
      id: tag.id,
      name: tag.name,
    }));
    const modifiedPhones = phoneNumbers.map((phoneNumber) => {
      const { id, ...rest } = phoneNumber;
      return rest;
    });
    const modifiedEmails = emails.map((email) => {
      const { id, ...rest } = email;
      return rest;
    });

    const editedContact = {
      id: contact.id,
      owner: contactPropietary.attributes.value,
      firstName: contactFirstname.attributes.value,
      lastName: contactLastname.attributes.value,
      listId: contactListSelectState.attributes.value,
      address1: {
        ...getEditedField(
          'street',
          contact.address1?.street,
          homeAddress.attributes.value
        ),
        ...getEditedField('city', contact.address1?.street, city.attributes.value),
        ...getEditedField('state', contact.address1?.street, state.attributes.value),
        ...getEditedField(
          'zipcode',
          contact.address1?.street,
          postalCode.attributes.value
        ),
        ...getEditedField(
          'country',
          contact.address1?.street,
          country.attributes.value
        ),
      },
      address2: {
        ...getEditedField(
          'street',
          contact.address2?.street,
          secondaryHomeAddress.attributes.value
        ),
        ...getEditedField(
          'city',
          contact.address2?.street,
          secondaryCity.attributes.value
        ),
        ...getEditedField(
          'state',
          contact.address2?.street,
          secondaryState.attributes.value
        ),
        ...getEditedField(
          'zipcode',
          contact.address2?.street,
          secondaryPostalCode.attributes.value
        ),
        ...getEditedField(
          'country',
          contact.address2?.street,
          secondaryCountry.attributes.value
        ),
      },
      emails: modifiedEmails,
      phones: modifiedPhones,
      // ims_whatsapp: modifiedImsWhatsapp,
      tags: modifiedTags,
      imageUrl: contactImage.imageUrl
        ? contactImage.removeParams(contactImage.imageUrl)
        : null,
      properties: customProperties,
      businessId: selectedBusiness[0]?.businessId || '',
      businessName: selectedBusiness[0]?.businessName || '',
      birthDate: birthDate?.toDate(),
    };

    return editedContact;
  };
  const addPhone = () => {
    const newPhone = contactHelper.createNewAddress(ChannelType.PHONE);
    const newPhoneNumbers = [...phoneNumbers, newPhone];
    setPhoneNumbers(newPhoneNumbers as IContactAddress[]);
  };
  const replaceAddress = (addressId: string, newAddress: IContactAddress) => {
    if (newAddress.channelType === 3) {
      const newEmails = emails.map((email) => {
        if (email.id === addressId) {
          return newAddress;
        }
        return email;
      });

      setEmails(newEmails);
    }
    if (newAddress.channelType === 5) {
      const newPhoneNumbers = phoneNumbers.map((phoneNumber) => {
        if (phoneNumber.id === addressId) {
          return newAddress;
        }
        return phoneNumber;
      });

      setPhoneNumbers(newPhoneNumbers);
    }
    if (newAddress.channelType === 13) {
      const newImsWhatsapp = imsWhatsapp.map((imsWhatsapp) => {
        if (imsWhatsapp.id === addressId) {
          return newAddress;
        }
        return imsWhatsapp;
      });
      setImsWhatsApp(newImsWhatsapp);
    }
    if (newAddress.channelType === 21) {
      const newImsRCS = imsRCS.map((imsRCS) => {
        if (imsRCS.id === addressId) {
          return newAddress;
        }
        return imsRCS;
      });
      setImsRCS(newImsRCS);
    }
  };

  const addEmail = () => {
    const newEmail = contactHelper.createNewAddress(ChannelType.EMAIL);
    const newEmails = [...emails, newEmail];
    setEmails(newEmails as IContactAddress[]);
  };
  const addWhatsapp = () => {
    const newWhatsapp = contactHelper.createNewAddress(ChannelType.WHATSAPP);
    const newWhatsapps = [...imsWhatsapp, newWhatsapp];
    setImsWhatsApp(newWhatsapps as IContactAddress[]);
  };
  const addRCS = () => {
    const newRCS = contactHelper.createNewAddress(ChannelType.RCS);
    const newRCSs = [...imsRCS, newRCS];
    setImsRCS(newRCSs as IContactAddress[]);
  };
  const addRCSVerified = (newRCS: IContactAddress) => {
    const updatedRCSs = [...imsWhatsapp, newRCS];
    setImsWhatsApp(updatedRCSs);
  };
  const addWhatsappVerified = (newWhatsapp: IContactAddress) => {
    const updatedWhatsapps = [...imsWhatsapp, newWhatsapp];
    setImsWhatsApp(updatedWhatsapps);
  };
  const handleAddressFieldChange = async (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldId: string,
    type: ChannelType
  ) => {
    const newValue = e.target.value;

    if (type === 3) {
      const newEmails = editContactHelper.getAddressesWithUpdatedValues(
        newValue,
        fieldId,
        emails
      );
      setEmails(newEmails);
    } else if (type === 5) {
      const newPhoneNumbers = editContactHelper.getAddressesWithUpdatedValues(
        newValue,
        fieldId,
        phoneNumbers
      );

      setPhoneNumbers(newPhoneNumbers);
    } else if (type === 13) {
      const newImsWhatsapp = editContactHelper.getAddressesWithUpdatedValues(
        newValue,
        fieldId,
        imsWhatsapp
      );

      setImsWhatsApp(newImsWhatsapp);
    } else if (type === 21) {
      const newImsRCS = editContactHelper.getAddressesWithUpdatedValues(
        newValue,
        fieldId,
        imsRCS
      );

      setImsRCS(newImsRCS);
    }
  };
  const handleNoteFieldChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldId: string,
    addressType: ChannelType
  ) => {
    if (addressType === 14) {
      const newImsFacebooks = imsFacebooks.map((imsFacebook) => {
        if (imsFacebook.id === fieldId) {
          return {
            ...imsFacebook,
            note: e.target.value,
          };
        }
        return imsFacebook;
      });
      setImsFacebooks(newImsFacebooks);
    }
    if (addressType === 20) {
      const newImsWebchats = imsWebchats.map((imsWebchat) => {
        if (imsWebchat.id === fieldId) {
          return {
            ...imsWebchat,
            note: e.target.value,
          };
        }
        return imsWebchat;
      });
      setImsWebchats(newImsWebchats);
    }
    if (addressType === 16) {
      const newImsInstagrams = imsInstagrams.map((imsInstagram) => {
        if (imsInstagram.id === fieldId) {
          return {
            ...imsInstagram,
            note: e.target.value,
          };
        }
        return imsInstagram;
      });
      setImsInstagrams(newImsInstagrams);
    }
    if (addressType === 3) {
      const newEmails = emails.map((email) => {
        if (email.id === fieldId) {
          return {
            ...email,
            note: e.target.value,
          };
        }
        return email;
      });
      setEmails(newEmails);
    }
    if (addressType === 5) {
      const newPhoneNumbers = phoneNumbers.map((phoneNumber) => {
        if (phoneNumber.id === fieldId) {
          return {
            ...phoneNumber,
            note: e.target.value,
          };
        }
        return phoneNumber;
      });
      setPhoneNumbers(newPhoneNumbers);
    }
    if (addressType === 13) {
      const newImsWhatsapp = imsWhatsapp.map((imsWhatsapp) => {
        if (imsWhatsapp.id === fieldId) {
          return {
            ...imsWhatsapp,
            note: e.target.value,
          };
        }
        return imsWhatsapp;
      });
      setImsWhatsApp(newImsWhatsapp);
    }
    if (addressType === 21) {
      const newImsRCS = imsRCS.map((item) => {
        if (item.id === fieldId) {
          return {
            ...item,
            note: e.target.value,
          };
        }
        return item;
      });
      setImsRCS(newImsRCS);
    }
  };
  const handleDeleteAddress = async (fieldId: string, addressType: ChannelType) => {
    if (
      addressType === 3 ||
      addressType === 5 ||
      addressType === 13 ||
      addressType === 14 ||
      addressType === 20 ||
      addressType === 16 ||
      addressType === 21
    ) {
      setIsDeletingAddress(fieldId);
      await dispatch(
        deleteContactAddress({
          contactId: contact.id,
          contactAddressId: fieldId,
          type: addressType,
        })
      );
      setIsDeletingAddress(false);
      switch (addressType) {
        case 3:
          const newEmails = emails.filter((email) => email.id !== fieldId);
          setEmails(newEmails);
          break;
        case 5:
          const newPhoneNumbers = phoneNumbers.filter(
            (phoneNumber) => phoneNumber.id !== fieldId
          );
          setPhoneNumbers(newPhoneNumbers);
          break;
        case 13:
          const newImsWhatsapp = imsWhatsapp.filter(
            (imsWhatsapp) => imsWhatsapp.id !== fieldId
          );
          setImsWhatsApp(newImsWhatsapp);
          break;
        case 21:
          const newImsRCS = imsRCS.filter((imsRCS) => imsRCS.id !== fieldId);
          setImsRCS(newImsRCS);
          break;
        case 14:
          const newImsFacebooks = imsFacebooks.filter(
            (imsFacebook) => imsFacebook.id !== fieldId
          );
          setImsFacebooks(newImsFacebooks);
          break;
        case 20:
          const newImsWebchats = imsWebchats.filter(
            (imsWebchat) => imsWebchat.id !== fieldId
          );
          setImsWebchats(newImsWebchats);
          break;
        case 16:
          const newImsInstagrams = imsInstagrams.filter(
            (imsInstagram) => imsInstagram.id !== fieldId
          );
          setImsInstagrams(newImsInstagrams);
          break;
        default:
          break;
      }
    }
  };
  function resetToInitialContactInformation() {
    setImsFacebooks(contact?.ims_facebook || []);
    setImsWebchats(contact?.ims_webchat || []);
    setImsInstagrams(contact?.ims_instagram || []);
    setImsWhatsApp(contact?.ims_whatsapp || []);
    setImsRCS(contact?.ims_rcs || []);
    setEmails(contact?.emails || []);
    setPhoneNumbers(contact?.phones || []);
  }
  function resetSelectedBusiness() {
    setSelectedBusiness([
      { businessId: contact.businessId, businessName: contact.businessName },
    ]);
  }

  function resetBirthDate() {
    setBirthDate(contact?.birthDate ? dayjs(contact.birthDate) : null);
  }
  function resetCustomProperty(nameKey: string) {
    const defaultProperty = contact.properties.find(
      (property) => property.nameKey === nameKey
    );
    const updatedProperties = customProperties.map((property) =>
      property.nameKey === nameKey
        ? { ...property, value: defaultProperty.value }
        : property
    );
    setCustomProperties(updatedProperties);
  }
  // Utils
  function replaceSpacesWithHyphen(input: string): string {
    const result = input.replace(/ /g, '-');

    return result;
  }

  const getFilteredCustomProperties = (): Property[] => {
    return customContactFields.map((customField) => {
      const customFieldWithHypen = replaceSpacesWithHyphen(customField.nameKey);

      const isThereProperty = contact.properties.some((property) => {
        return customFieldWithHypen === property.nameKey;
      });

      if (isThereProperty) {
        return contact.properties.find(
          (property) => property.nameKey === customFieldWithHypen
        );
      }

      return {
        nameKey: customField.nameKey,
        type: customField.type,
        value: '',
      };
    });
  };

  const addBusiness = (business: IContactInfo) => {
    // setBusinessName(business.name);
    // setBusinessId(business.id);
  };

  useEffect(() => {
    if (contact && customContactFields) {
      setCustomProperties(getFilteredCustomProperties());
    }
  }, [contact, customContactFields]);

  useEffect(() => {
    if (contact?.emails && contact?.phones) {
      setEmails(contact.emails);
      setPhoneNumbers(contact.phones);
    }
    setImsWhatsApp(contact?.ims_whatsapp || []);
    setImsRCS(contact?.ims_rcs || []);
    setImsFacebooks(contact?.ims_facebook || []);
    setImsWebchats(contact?.ims_webchat || []);
    setImsInstagrams(contact?.ims_instagram || []);
  }, [contact]);

  useEffect(() => {
    if (contact?.tags) {
      labelMultipleSelect.actions.setValue(contact.tags);
    }
    if (contact?.businessId) {
      setSelectedBusiness([
        { businessId: contact.businessId, businessName: contact.businessName },
      ]);
    }
  }, [contact]);

  return {
    field: {
      birthDate,
      imsWhatsapp,
      imsRCS,
      contactListSelectState,
      contactFirstname,
      contactLastname,
      contactImage,
      contactPropietary,
      imsFacebooks,
      imsWebchats,
      imsInstagrams,
      emails,
      phoneNumbers,
      homeAddress,
      secondaryHomeAddress,
      city,
      secondaryCity,
      state,
      secondaryState,
      postalCode,
      secondaryPostalCode,
      country,
      secondaryCountry,
      customProperties,
      labelMultipleSelect,
      selectedBusiness,
    },
    action: {
      setBirthDate,
      setCustomProperties,
      getEditedContact,
      handleAddressFieldChange,
      handleNoteFieldChange,
      handleDeleteAddress,
      addPhone,
      addEmail,
      addWhatsapp,
      addRCS,
      addBusiness,
      replaceAddress,
      setSelectedBusiness,
      addWhatsappVerified,
      resetToInitialContactInformation,
      resetSelectedBusiness,
      resetBirthDate,
      resetCustomProperty,
    },
    state: {
      isDeletingAddress,
    },
  };
};

export default useEditContact;
