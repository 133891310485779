import axios from 'axios';
// Utils
import getRequestConfig from 'ReduxToolkit/functions/getRequestConfig';
// Types
import { IContact, IContactAddress, IContactField } from '@trii/types/dist/Contacts';
import type { ContactTableData } from './types/ContactTableData';
import type { Pagination } from './types/Pagination';
import type { FieldsFilter } from './types/FieldsFilter';
import type For from 'types/For';
import type BusinessTableData from './types/BusinessTableData';
import type { DeleteContactAddressData } from './types/DeleteContactAddressData';
import { UpdateContactLabels } from './types/UpdateContactLabels';
import { CheckContactAddressData } from './types/CheckContactAddressData';
import { UpdateContactAddressData } from './types/UpdateContactAddressData';
import { resultContactAddressCheck } from '@trii/types/dist/ApiResponses';
import { SetContactAddressRequestData } from './types/SetContactAddressRequestData';

const fetchContactsTableData = async (
  jwtToken: string,
  URL: string,
  paginationFetchData: Pagination
): Promise<ContactTableData> => {
  const config = getRequestConfig.contentTypeJson(jwtToken);
  const paginationFetchDataJson = JSON.stringify(paginationFetchData);

  const response = await axios.post<ContactTableData>(
    `${URL}/Search`,
    paginationFetchDataJson,
    config
  );

  return response.data;
};

const fetchBusinessTableData = async (
  jwtToken: string,
  URL: string,
  paginationFetchData: Pagination
): Promise<BusinessTableData> => {
  const config = getRequestConfig.contentTypeJson(jwtToken);
  const paginationFetchDataJson = JSON.stringify(paginationFetchData);

  const response = await axios.post<BusinessTableData>(
    `${URL}/Search`,
    paginationFetchDataJson,
    config
  );

  return response.data;
};

const fetchFields = async (
  jwtToken: string,
  URL: string,
  filterBy: FieldsFilter,
  fetchFor: For
): Promise<IContactField[]> => {
  const config = getRequestConfig.basic(jwtToken);
  const response = await axios.get<IContactField[]>(
    `${URL}/ContactFields?filter=${filterBy}&for=${fetchFor}`,
    config
  );
  return response.data;
};

const fetchContact = async (
  jwtToken: string,
  URL: string,
  contactId: string
): Promise<IContact> => {
  const config = getRequestConfig.basic(jwtToken);
  const response = await axios.get<IContact>(`${URL}/contacts/${contactId}`, config);

  return response.data;
};

const uploadPhoto = async (
  jwtToken: string,
  URL_MEDIA: string,
  file: FormData,
  contactId: string
): Promise<string> => {
  const config = getRequestConfig.contentTypeFormData(jwtToken);
  const response = await axios.post<string>(
    `${URL_MEDIA}/upload?module=contacts&folderType=avatars&contactId=${contactId}`,
    file,
    config
  );

  return response.data;
};

const deleteUserPhoto = async (
  jwtToken: string,
  URL: string,
  contactId: string
): Promise<void> => {
  const config = getRequestConfig.basic(jwtToken);
  await axios.delete(`${URL}/contacts/${contactId}/photo`, config);
};

const updateContact = async (
  jwtToken: string,
  URL: string,
  contactJson: any,
  contactId: string
): Promise<IContact> => {
  const config = getRequestConfig.contentTypeJson(jwtToken);
  const response = await axios.put<IContact>(
    `${URL}/contacts/${contactId}`,
    contactJson,
    config
  );

  return response.data;
};

const createContact = async (jwtToken: string, URL: string, contactJson: string) => {
  const config = getRequestConfig.contentTypeJson(jwtToken);
  const response = await axios.post(`${URL}/contacts`, contactJson, config);
  return response.data;
};

const exportContacts = async (jwtToken: string, URL: string): Promise<void> => {
  const config = getRequestConfig.basic(jwtToken);
  await axios.get(`${URL}/Export`, config);
};

const fetchLabels = async (jwt: string, URL: string) => {
  const config = getRequestConfig.basic(jwt);

  const response = await axios.get(`${URL}/labels`, config);
  return response.data;
};

const deleteContacts = async (
  jwtToken: string,
  URL: string,
  contactIds: string[]
): Promise<void> => {
  const config = getRequestConfig.contentTypeJson(jwtToken);
  const contactIdsJson = JSON.stringify({ ids: contactIds });

  const response = await axios.delete(`${URL}/contacts/batch`, {
    ...config,
    data: contactIdsJson,
  });

  return response.data;
};

const updateContactLabels = async (
  jwtToken: string,
  URL: string,
  updateContactLabels: UpdateContactLabels
): Promise<void> => {
  const config = getRequestConfig.contentTypeJson(jwtToken);
  const updateContactLabelsJson = JSON.stringify(updateContactLabels);

  const response = await axios.put(
    `${URL}/contacts/batch`,
    updateContactLabelsJson,
    config
  );

  return response.data;
};

const fetchCloneContact = async (
  jwtToken: string,
  URL: string,
  contactId: string
): Promise<IContact> => {
  const config = getRequestConfig.contentTypeJson(jwtToken);
  const response = await axios.post(
    `${URL}/contacts/clone/${contactId}`,
    {},
    config
  );

  return response.data;
};

const deleteContact = async (
  jwtToken: string,
  URL: string,
  contactId: string
): Promise<void> => {
  const config = getRequestConfig.basic(jwtToken);
  await axios.delete(`${URL}/contacts/${contactId}`, config);
};

const deleteContactAddress = async (
  jwtToken: string,
  URL: string,
  deleteData: DeleteContactAddressData
) => {
  const config = getRequestConfig.contentTypeJson(jwtToken);
  const configWithData = { ...config, data: deleteData };
  const response = await axios.delete<IContact>(
    `${URL}/contactAddresses`,
    configWithData
  );

  return response.data;
};

const checkContactAddress = async (
  jwtToken: string,
  URL: string,
  data: CheckContactAddressData
): Promise<resultContactAddressCheck> => {
  const config = getRequestConfig.contentTypeJson(jwtToken);
  const response = await axios.post<resultContactAddressCheck>(
    `${URL}/contactAddresses/check`,
    data,
    config
  );
  console.log("success", response)
  return response.data;
};

const setContactAddress = async (
  jwtToken: string,
  URL: string,
  data: SetContactAddressRequestData
): Promise<IContactAddress> => {
  const config = getRequestConfig.contentTypeJson(jwtToken);
  const response = await axios.post<IContactAddress>(
    `${URL}/contactAddresses/set`,
    data,
    config
  );

  return response.data;
};

const updateContactAddress = async (
  jwtToken: string,
  URL: string,
  data: UpdateContactAddressData
) => {
  const { contactAddressId } = data;
  const config = getRequestConfig.contentTypeJson(jwtToken);
  const response = await axios.put<IContactAddress>(
    `${URL}/contactAddresses/${contactAddressId}`,
    data,
    config
  );

  return response.data;
};

const deleteContactsPermanently = async (
  jwtToken: string,
  URL: string,
  contactIds: string[]
): Promise<void> => {
  const config = getRequestConfig.contentTypeJson(jwtToken);
  const contactIdsJson = JSON.stringify({ ids: contactIds });

  const response = await axios.delete(`${URL}/contacts/batch/permanently`, {
    ...config,
    data: contactIdsJson,
  });

  return response.data;
};

const restoreContacts = async (
  jwtToken: string,
  URL: string,
  contactIds: string[]
): Promise<void> => {
  const config = getRequestConfig.contentTypeJson(jwtToken);

  const response = await axios.put(
    `${URL}/contacts/batch/restore`,
    { ids: contactIds },
    config
  );

  return response.data;
};

export default {
  fetchFields,
  fetchContactsTableData,
  fetchContact,
  deleteUserPhoto,
  updateContact,
  createContact,
  uploadPhoto,
  exportContacts,
  fetchBusinessTableData,
  fetchLabels,
  deleteContacts,
  updateContactLabels,
  fetchCloneContact,
  deleteContact,
  deleteContactAddress,
  checkContactAddress,
  setContactAddress,
  updateContactAddress,
  deleteContactsPermanently,
  restoreContacts,
};
