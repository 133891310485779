import {
  Box,
  Button,
  Chip,
  IconButton,
  Modal,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
// import {
//   fetchSubscriptions,
//   postUnsubscribe,
// } from 'redux/features/contactInfoSlice/contactInfoSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { IContactAddress } from '@trii/types/dist/Contacts';

interface ModalSubscriptionProps {
  openModal: boolean;
  onClose: () => void;
  icon: React.ReactNode;
  contactAddress: IContactAddress;
}

const ModalSubscription = ({
  openModal,
  onClose,
  icon,
  contactAddress: initialContactAddress,
}: ModalSubscriptionProps) => {
  const { t } = useTranslation();
  const style = {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '0.5rem',
    boxShadow: 15,
  };
  const theme = useTheme();

  const dispatch = useAppDispatch();

  const [subscriptionList, setSubscriptionList] = useState([]);
  const [contactAddress, setContactAddress] = useState(null);

  useEffect(() => {
    if (initialContactAddress && contactAddress === null) {
      setContactAddress(initialContactAddress);
    }
  }, [initialContactAddress]);

  // useEffect(() => {
  //   if (!openModal) return;

  //   const fetchData = async () => {
  //     try {
  //       const actionResult = await dispatch(fetchSubscriptions());
  //       const data = actionResult.payload;

  //       setSubscriptionList(data);
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };

  //   fetchData();
  // }, [openModal]);

  // const unsubscribeBtn = async (contactAddressId, subscriptionId) => {
  //   try {
  //     const actionResult = await dispatch(
  //       postUnsubscribe({
  //         contactAdressId: contactAddressId,
  //         subscriptionId: subscriptionId,
  //       })
  //     );

  //     const response = actionResult.payload;

  //     //@ts-ignore
  //     setContactAddress(response.data as IContactAddress);

  //     return response;
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };

  return (
    <Modal
      open={openModal}
      onClose={onClose}
      sx={{
        display: 'grid',
        placeItems: 'center',
      }}
    >
      <Box
        sx={{
          ...style,
          width: '50%',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: '25%',
          },
          maxHeight: '80vh',
          // padding: 1,
          overflow: 'auto',
          overflowX: 'hidden',
          position: 'absolute',
          //@ts-ignore
          backgroundColor: (theme) => theme.palette.background.panel,
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            position: 'sticky',
            top: 0,
            padding: '10px',
            paddingLeft: 2,
            //@ts-ignore
          backgroundColor: (theme) => theme.palette.background.panel,
            zIndex: 1,
          }}
        >
          <Box display={'flex'} alignItems={'center'}>
            <Typography
              variant="h6"
              sx={{
                color: (theme) => theme.palette.text.primary,
                fontWeight: 600,
                marginBottom: '0',
                marginRight: '2rem',
              }}
            >
              {t('navBar.subscriptions')}
            </Typography>
            <Box display={'flex'} alignItems={'center'} gap={1}>
              <Tooltip
                title={contactAddress?.channelType === 13 ? 'WhatsApp' : ''}
                arrow
              >
                <Box
                  className="iconLastMessage"
                  sx={{
                    '& svg': {
                      color:
                        contactAddress?.channelType === 13
                          ? theme.palette.success.main
                          : theme.palette.text.primary,
                    },
                  }}
                >
                  {icon}
                </Box>
              </Tooltip>
              <Typography
                variant="h6"
                sx={{
                  color: (theme) => theme.palette.text.primary,
                  fontWeight: 400,
                  // marginBottom: '0.8rem',
                  fontSize: '0.925rem',
                  marginTop: '2px'
                }}
              >
                {contactAddress?.address}
              </Typography>
            </Box>
          </Box>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        {/* Body */}
        <Box
          className="conversations-panel-inner my-0"
          display={'flex'}
          // boxShadow={shadow.md}
          px={{ md: '1rem', lg: '1rem' }}
          sx={{
            color: (theme) => theme.palette.text.primary,
            //@ts-ignore
            backgroundColor: (theme) => theme.palette.background.panel,
            border: 'unset',
            padding: 1,
          }}
        >
          {subscriptionList &&
            subscriptionList.map((value) => (
              <Box
                key={value.id}
                className="conversations-d-flex conversations-justify-content-between conversations-w-100"
                alignItems={'center'}
                sx={{
                  borderBottom: '1px solid',
                  borderColor: (theme) => theme.palette.divider,
                  paddingY: '2px',
                }}
              >
                <Box className="d-inline-block conversations-w-100 py-1 pr-4">
                  <Typography
                    //   fontWeight={fontWeight.semibold}
                    //   fontSize={fontSize.sm}
                    sx={{ color: (theme) => theme.palette.text.primary }}
                  >
                    {value && value.name}
                  </Typography>
                  <br />

                  <Box display="flex" alignItems={'center'}>
                    <Chip
                      size="small"
                      label={
                        contactAddress?.subscriptionsRejected?.findIndex(
                          (x) => x == value.id
                        ) >= 0
                          ? t('conversations.contactInfo.unsubscribed')
                          : t('conversations.contactInfo.active')
                      }
                      color={
                        contactAddress?.subscriptionsRejected?.findIndex(
                          (x) => x == value.id
                        ) >= 0
                          ? 'error'
                          : 'success'
                      }
                      sx={{
                        opacity: 0.85,
                        borderRadius: '4px',
                        height: '18px',
                        '& span': {
                          paddingLeft: '5px',
                          paddingRight: '5px',
                        },
                      }}
                    />
                    <Typography
                      sx={{
                        marginLeft: '1rem',
                        fontSize: '0.8rem',
                        display: 'none',
                      }}
                    >
                      14/05/2024
                    </Typography>
                  </Box>
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={
                    contactAddress?.subscriptionsRejected?.findIndex(
                      (x) => x == value.id
                    ) >= 0
                  }
                  // onClick={() => unsubscribeBtn(contactAddress.id, value.id)}
                >
                  {t('conversations.contactInfo.unsubscribe')}
                </Button>
              </Box>
            ))}
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalSubscription;
