import { useTranslation } from 'react-i18next';
// Components/ui
import { Modal, Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';
import {
  Facebook,
  Instagram,
  Mails,
  Phones,
  RCSs,
  WebChat,
  Whatsapps,
} from './components';
// Redux
import { useSelector } from 'react-redux';
import { selectContactData } from 'ReduxToolkit/features/contactsSlice/contactsSlice';
// Hooks
import useEditContact from 'features/Views/ContactEdit/hooks/useEditContact/useEditContact';
import { useContext } from 'react';
import { ContactEditContext } from 'features/Views/ContactEdit/context/ContactEditContext';

interface EditModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
}

const StyledModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(4),
  boxShadow: theme.shadows[5],
  borderRadius: theme.shape.borderRadius,
}));

const EditModal: React.FC<EditModalProps> = ({ open, onClose, title }) => {
  const { t } = useTranslation();

  const { editContact } = useContext(ContactEditContext);

  const { emails, phoneNumbers, imsWhatsapp, imsRCS } = editContact.field;
  const { addEmail, addPhone, addWhatsapp, resetToInitialContactInformation, addRCS } =
    editContact.action;

  function handleClose() {
    onClose();
    resetToInitialContactInformation();
  }

  const renderContent = () => {
    switch (title) {
      case 'Phones':
        return <Phones handleAdd={addPhone} phoneNumbers={phoneNumbers} />;
      case 'Emails':
        return <Mails handleAdd={addEmail} emails={emails} />;
      case 'Facebook':
        return <Facebook />;
      case 'Whatsapp':
        return <Whatsapps handleAdd={addWhatsapp} whatsapps={imsWhatsapp} />;
      case 'RCS':
        return <RCSs handleAdd={addRCS} RCSs={imsRCS} />;
      case 'Webchat':
        return <WebChat />;
      case 'Instagram':
        return <Instagram />;
      default:
        return <Typography>No information available for this section.</Typography>;
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <StyledModalContent>
        {renderContent()}
        <Box mt={2} display={'flex'} justifyContent={'flex-end'}>
          <Button variant="contained" color="primary" onClick={handleClose}>
            {t('global.close')}
          </Button>
        </Box>
      </StyledModalContent>
    </Modal>
  );
};

export default EditModal;
