import { useEffect, useState } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
import { selectCustomContactFields } from 'ReduxToolkit/features/contactsSlice/contactsSlice';
import { selectUser } from 'ReduxToolkit/features/userSlice/userSlice';
// Types
import { IContactInfo, IContactAddress, Property } from '@trii/types/dist/Contacts';
import type UseCreateContact from './types/UseCreateContact';
import type { ChangeEvent } from 'react';
import { ChannelType } from '@trii/types/dist/Common/Channels';
// Hooks
import useField from 'hooks/useField';
import useImage from 'hooks/useImage';
import useSelect from 'hooks/useSelect';
// Utils
import contactHelper from 'utils/contactHelper';
import createContactHelper from './utils/createContactHelper';
import { useMultipleSelect } from 'hooks/useMultipleSelect';
import { NewAddress } from 'types/NewAddress';
import { Dayjs } from 'dayjs';
import { SimplifiedBusiness } from 'components/ContactCreateEditAppBody/components/DetailsSection/components/BusinessSelect/types';
import { RCS } from 'features/Views/ContactView/components/BodyContactViewer/components/DetailsCard/components/ContactSection/components/EditModal/components/RCSs/components/Body/components';

const useCreateContact = (): UseCreateContact => {
  const user = useSelector(selectUser);
  const { t } = useTranslation();
  const { getField, createProperty } = createContactHelper;
  const customContactFields = useSelector(selectCustomContactFields);
  const [customProperties, setCustomProperties] = useState<Property[]>([]);
  // General section fields
  const contactFirstname = useField('text');
  const contactLastname = useField('text');
  const contactPropietary = useSelect(user.uid);
  const [birthDate, setBirthDate] = useState<Dayjs | null>(null);
  const contactListSelectState = useSelect('');
  const labelMultipleSelect = useMultipleSelect();
  const [selectedBusiness, setSelectedBusiness] = useState<SimplifiedBusiness[]>([]);
  // Image section input
  const contactImage = useImage('');
  // Contact information fields
  const [imsFacebooks, setImsFacebooks] = useState<IContactAddress[]>([]);
  const [imsWebchats, setImsWebchats] = useState<IContactAddress[]>([]);
  const [imsInstagrams, setImsInstagrams] = useState<IContactAddress[]>([]);
  const [imsWhatsapp, setImsWhatsApp] = useState<NewAddress[]>([]);
  const [imsRCS, setImsRCS] = useState<NewAddress[]>([]);
  const [emails, setEmails] = useState<NewAddress[]>([]);
  const [phoneNumbers, setPhoneNumbers] = useState<NewAddress[]>([]);
  // Address iformation fields
  const homeAddress = useField('text');
  const secondaryHomeAddress = useField('text');
  const city = useField('text');
  const secondaryCity = useField('text');
  const state = useField('text');
  const secondaryState = useField('text');
  const postalCode = useField('text');
  const secondaryPostalCode = useField('text');
  const country = useField('text');
  const secondaryCountry = useField('text');

  const verifyCompletion = () => {
    if (
      contactFirstname.attributes.value !== '' ||
      contactLastname.attributes.value !== ''
    ) {
      contactFirstname.actions.onSuccess();
      contactLastname.actions.onSuccess();
      return true;
    } else {
      contactFirstname.actions.onError(t('contactCreate.requiredFields'));
      contactLastname.actions.onError(t('contactCreate.requiredFields'));
      return false;
    }
  };

  // Actions
  const getContact = () => {
    if (verifyCompletion()) {
      const modifiedTags = labelMultipleSelect.attributes.value.map((tag) => ({
        id: tag.id,
        name: tag.name,
      }));
      const modifiedPhones = phoneNumbers.map((phoneNumber) => {
        const { id, ...rest } = phoneNumber;
        return rest;
      });
      const modifiedEmails = emails.map((email) => {
        const { id, ...rest } = email;
        return rest;
      });
      const modifiedImsWhatsapp = imsWhatsapp.map((whatsapp) => {
        const { id, ...rest } = whatsapp;
        return rest;
      });

      const properties = [...customProperties];
      const newContact = {
        ...getField('firstName', contactFirstname.attributes.value),
        ...getField('lastName', contactLastname.attributes.value),
        ...getField('owner', contactPropietary.attributes.value),
        ...getField('listId', contactListSelectState.attributes.value),
        ...getField('imageUrl', contactImage.imageUrl),
        address1: {
          ...getField('street', homeAddress.attributes.value),
          ...getField('city', city.attributes.value),
          ...getField('state', state.attributes.value),
          ...getField('zipcode', postalCode.attributes.value),
          ...getField('country', country.attributes.value),
        },
        address2: {
          ...getField('street', secondaryHomeAddress.attributes.value),
          ...getField('city', secondaryCity.attributes.value),
          ...getField('state', secondaryState.attributes.value),
          ...getField('zipcode', secondaryPostalCode.attributes.value),
          ...getField('country', secondaryCountry.attributes.value),
        },
        emails: modifiedEmails,
        phones: modifiedPhones,
        ims_whatsapp: modifiedImsWhatsapp,
        tags: modifiedTags,
        businessId: selectedBusiness[0]?.businessId,
        businessName: selectedBusiness[0]?.businessName,
        properties,
        birthDate: birthDate?.toDate(),
      };
      return newContact;
    }
  };
  const addPhone = () => {
    const newPhone = contactHelper.createNewAddress(ChannelType.PHONE);
    const newPhoneNumbers = [...phoneNumbers, newPhone];
    setPhoneNumbers(newPhoneNumbers);
  };
  const addEmail = () => {
    const newEmail = contactHelper.createNewAddress(ChannelType.EMAIL);
    const newEmails = [...emails, newEmail];
    setEmails(newEmails);
  };
  const addWhatsapp = () => {
    const newWhatsapp = contactHelper.createNewAddress(ChannelType.WHATSAPP);
    const newWhatsapps = [...imsWhatsapp, newWhatsapp];
    setImsWhatsApp(newWhatsapps);
  };
  const addRCS = () => {
    const newRCS = contactHelper.createNewAddress(ChannelType.RCS);
    const newRCSs = [...imsRCS, newRCS];
    setImsWhatsApp(newRCSs);
  };
  const handlePhoneNumbersNumberFieldChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldId: string
  ) => {
    const newPhoneNumbers = phoneNumbers.map((phoneNumber) => {
      if (phoneNumber.id === fieldId) {
        return {
          ...phoneNumber,
          address: e.target.value,
        };
      }
      return phoneNumber;
    });
    setPhoneNumbers(newPhoneNumbers);
  };
  const handleEmailsEmailFieldChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldId: string
  ) => {
    const newEmails = emails.map((email) => {
      if (email.id === fieldId) {
        return {
          ...email,
          address: e.target.value,
        };
      }
      return email;
    });
    setEmails(newEmails);
  };
  const handleWhatsappsAddressFieldChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldId: string
  ) => {
    const newWhatsapp = imsWhatsapp.map((whatsapp) => {
      if (whatsapp.id === fieldId) {
        return {
          ...whatsapp,
          address: e.target.value,
        };
      }
      return whatsapp;
    });
    setImsWhatsApp(newWhatsapp);
  };
  const handleRCSAddressFieldChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldId: string
  ) => {
    const newRCS = imsRCS.map((rcs) => {
      if (rcs.id === fieldId) {
        return {
          ...rcs,
          address: e.target.value,
        };
      }
      return rcs;
    });
    setImsRCS(newRCS);
  };
  const handleNoteFieldChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldId: string,
    addressType: ChannelType
  ) => {
    if (addressType === 14) {
      const newImsFacebooks = imsFacebooks.map((imsFacebook) => {
        if (imsFacebook.id === fieldId) {
          return {
            ...imsFacebook,
            note: e.target.value,
          };
        }
        return imsFacebook;
      });
      setImsFacebooks(newImsFacebooks);
    }
    if (addressType === 20) {
      const newImsWebchats = imsWebchats.map((imsWebchat) => {
        if (imsWebchat.id === fieldId) {
          return {
            ...imsWebchat,
            note: e.target.value,
          };
        }
        return imsWebchat;
      });
      setImsWebchats(newImsWebchats);
    }
    if (addressType === 16) {
      const newImsInstagrams = imsInstagrams.map((imsInstagram) => {
        if (imsInstagram.id === fieldId) {
          return {
            ...imsInstagram,
            note: e.target.value,
          };
        }
        return imsInstagram;
      });
      setImsInstagrams(newImsInstagrams);
    }
    if (addressType === 3) {
      const newEmails = emails.map((email) => {
        if (email.id === fieldId) {
          return {
            ...email,
            note: e.target.value,
          };
        }
        return email;
      });
      setEmails(newEmails);
    }
    if (addressType === 5) {
      const newPhoneNumbers = phoneNumbers.map((phoneNumber) => {
        if (phoneNumber.id === fieldId) {
          return {
            ...phoneNumber,
            note: e.target.value,
          };
        }
        return phoneNumber;
      });
      setPhoneNumbers(newPhoneNumbers);
    }
  };
  const handleDeleteAddress = (fieldId: string, addressType: ChannelType) => {
    if (addressType === 14) {
      const newImsFacebooks = imsFacebooks.filter(
        (imsFacebook) => imsFacebook.id !== fieldId
      );
      setImsFacebooks(newImsFacebooks);
    }
    if (addressType === 20) {
      const newImsWebchats = imsWebchats.filter(
        (imsWebchat) => imsWebchat.id !== fieldId
      );
      setImsWebchats(newImsWebchats);
    }
    if (addressType === 16) {
      const newImsInstagrams = imsInstagrams.filter(
        (imsInstagram) => imsInstagram.id !== fieldId
      );
      setImsInstagrams(newImsInstagrams);
    }
    if (addressType === 13) {
      const newImsWhatsapp = imsWhatsapp.filter(
        (whatsapp) => whatsapp.id !== fieldId
      );
      setImsWhatsApp(newImsWhatsapp);
    }
    if (addressType === 3) {
      const newEmails = emails.filter((email) => email.id !== fieldId);
      setEmails(newEmails);
    }
    if (addressType === 5) {
      const newPhoneNumbers = phoneNumbers.filter(
        (phoneNumber) => phoneNumber.id !== fieldId
      );
      setPhoneNumbers(newPhoneNumbers);
    }
  };

  const addBusiness = (business: IContactInfo) => {
    setSelectedBusiness([
      {
        businessId: business.businessId,
        businessName: business.businessName,
      },
    ]);
  };

  useEffect(() => {
    if (customContactFields) {
      const newCustomProperties = customContactFields.map((customField) =>
        createProperty(customField)
      );

      setCustomProperties(newCustomProperties);
    }
  }, [customContactFields]);

  return {
    field: {
      birthDate,
      contactFirstname,
      contactLastname,
      contactImage,
      contactPropietary,
      imsFacebooks,
      imsWebchats,
      imsInstagrams,
      imsRCS,
      emails,
      phoneNumbers,
      homeAddress,
      secondaryHomeAddress,
      city,
      secondaryCity,
      state,
      secondaryState,
      postalCode,
      secondaryPostalCode,
      country,
      secondaryCountry,
      customProperties,
      contactListSelectState,
      labelMultipleSelect,
      imsWhatsapp,
      selectedBusiness,
    },
    action: {
      addWhatsapp,
      setCustomProperties,
      setBirthDate,
      getContact,
      handleNoteFieldChange,
      handlePhoneNumbersNumberFieldChange,
      handleEmailsEmailFieldChange,
      handleWhatsappsAddressFieldChange,
      handleRCSAddressFieldChange,
      handleDeleteAddress,
      addPhone,
      addEmail,
      addBusiness,
      addRCS,
      setSelectedBusiness,
    },
  };
};

export default useCreateContact;
