// Provider
import NotesCardContextProvider from './context/NotesCardContextProvider';
//Redux
import { useSelector } from 'react-redux';
import { selectNotes } from 'ReduxToolkit/features/noteSlice/noteSlice';
// Components/ui
import { InputAddNote, Note } from './components';
import { AccordionCard } from '../../layout';

interface FilesCardProps {
  title: string;
}
const NotesCard = ({ title }: FilesCardProps) => {
  const notes = useSelector(selectNotes);
  const totalNotes = notes?.length;
  return (
    <NotesCardContextProvider>
      <AccordionCard title={title} id="notes" totalItems={totalNotes}>
        {notes && notes.map((note) => <Note key={note.id} data={note} />)}
        <InputAddNote />
      </AccordionCard>
    </NotesCardContextProvider>
  );
};

export default NotesCard;
