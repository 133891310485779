// BodyContactContext.tsx
import React, { createContext, useState, useContext } from 'react';
import { BodyContactContextProps, IBodyContactContext } from './BodyContactContextType';

const BodyContactContext = createContext<IBodyContactContext>({
  isEditing: false,
  setIsEditing: () => {},
  handleEditMode: () => {},

});

export const useEditMode = () => {
  const context = useContext(BodyContactContext);
  if (!context) {
    throw new Error('useEditMode must be used within a BodyContactProvider');
  }
  return context;
};

export const BodyContactProvider: React.FC<BodyContactContextProps> = ({ children }) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const handleEditMode = () => {
    console.log("handleEditMode", isEditing)
    setIsEditing((prev) => !prev);
  };

  return (
    <BodyContactContext.Provider value={{ isEditing, setIsEditing, handleEditMode }}>
      {children}
    </BodyContactContext.Provider>
  );
};
