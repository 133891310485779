import { useContext, useState } from 'react';
import { StyleContext } from 'style/styleProvider';
// Components/ui
import {
  Box,
  CircularProgress,
  IconButton,
  styled,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import {
  CustomPropertyDatePicker,
  CustomPropertyDateRangePicker,
  CustomPropertyInput,
  CustomPropertyOptionSelect,
  CustomPropertyTimePicker,
  CustomPropertyTimeRangePicker,
} from './components';
// Types
import { ContactField_type, Property } from '@trii/types/dist/Contacts';
import { RootState } from 'ReduxToolkit/store';
// Redux
import {
  getContactPropertyValue,
  selectCustomContactFields,
  updateContact,
} from 'ReduxToolkit/features/contactsSlice/contactsSlice';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/useAppDispatch';
// Hooks
import UseEditContact from 'features/Views/ContactEdit/hooks/useEditContact/types/UseEditContact';
// Utils
import dayjs, { Dayjs } from 'dayjs';
import { selectUser } from 'ReduxToolkit/features/userSlice/userSlice';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  opacity: 0,
  transition: 'opacity 0.3s ease-in-out',
  '& .MuiSvgIcon-root': {
    width: '0.8em',
    height: '0.8em',
  },
  marginLeft: '15px',
}));

const StyledSaveCancelButton = styled(IconButton)(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    width: '0.8em',
    height: '0.8em',
  },
}));

const ContactBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  borderRadius: theme.shape.borderRadius,
  minWidth: '45%',
  '&:hover .edit-icon': {
    opacity: 1,
  },
}));

const FieldName = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  alignSelf: 'center',
  color: theme.palette.text.primary,
  letterSpacing: -0.5,
  padding: 0,
}));

const FieldValue = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  padding: '2.5px 14px 8.5px 0px',
  color: theme.palette.text.primary,
  wordWrap: 'break-word',
  maxHeight: '80px',
  overflow: 'auto',
  whiteSpace: 'break-spaces',
}));

type Props = {
  customProperty: Property;
  contact: UseEditContact;
};

const ContactCustomFieldLabel = ({ customProperty, contact }: Props) => {
  const dispatch = useAppDispatch();

  const { customProperties } = contact.field;
  const { setCustomProperties } = contact.action;

  const { fontSize, mediaQuery } = useContext(StyleContext);

  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const customContactFields = useSelector(selectCustomContactFields);
  const user = useSelector(selectUser);

  const property = useSelector((state: RootState) =>
    getContactPropertyValue(state, customProperty.nameKey)
  );

  const toggleEdit = () => setIsEditing((prev) => !prev);

  const handleCancel = () => {
    setIsEditing(false);
    contact.action.resetCustomProperty(customProperty.nameKey);
  };

  const handleSave = async () => {
    setIsSaving(true);

    const { type, nameKey, value } = customProperty;
    const updatedContactData = {
      properties: [
        {
          nameKey,
          type,
          value,
        },
      ],
    };

    await dispatch(updateContact(updatedContactData));

    setIsSaving(false);
    setIsEditing(false);
  };

  const getPropertyName = (propertyNameKey: string) => {
    const foundField = customContactFields.find(
      (field) => field.nameKey === propertyNameKey
    );

    return foundField?.name;
  };

  const userDateFormat = user.regCon_dateFormat || 'YYYY/MM/DD';
  const userTimeFormat = user.regCon_timeFormat || 'HH:mm';

  const handleInputChange = (name: string, value: any) => {
    // Verificar si los valores son instancias válidas de Dayjs
    const updatedValue =
      customProperty.type === ContactField_type.DATE
        ? dayjs(value).isValid()
          ? dayjs(value).format(userDateFormat)
          : 'Invalid Date'
        : customProperty.type === ContactField_type.TIME
        ? dayjs(value).isValid()
          ? dayjs(value).format(userTimeFormat)
          : 'Invalid Time'
        : value;

    const updatedProperties = customProperties.map((property) =>
      property.nameKey === name
        ? { ...property, value: updatedValue }
        : property
    );

    setCustomProperties(updatedProperties);
  };

  const { type, nameKey, value } = customProperty;
  const EditField = () => {
    if (type === ContactField_type.DATE)
      return (
        <CustomPropertyDatePicker
          value={value ? dayjs(value) : null}
          handleChange={(newValue) =>
            handleInputChange(nameKey, newValue.toDate())
          }
        />
      );
    else if (type === ContactField_type.TIME)
      return (
        <CustomPropertyTimePicker
          value={value ? dayjs(value) : null}
          handleChange={(newValue) =>
            handleInputChange(nameKey, newValue.toDate())
          }
        />
      );
    else if (type === ContactField_type.DATERANGE)
      return (
        <CustomPropertyDateRangePicker
          value={
            value
              ? ([dayjs(value[0]), dayjs(value[1])] as [Dayjs, Dayjs])
              : null
          }
          handleChange={(newValue) =>
            handleInputChange(nameKey, [
              newValue[0].toDate(),
              newValue[1].toDate(),
            ])
          }
        />
      );
    else if (type === ContactField_type.TIMERANGE)
      return (
        <CustomPropertyTimeRangePicker
          value={
            value
              ? ([dayjs(value[0]), dayjs(value[1])] as [Dayjs, Dayjs])
              : null
          }
          handleChange={(newValue) =>
            handleInputChange(nameKey, [
              newValue[0].toDate(),
              newValue[1].toDate(),
            ])
          }
        />
      );
    else if (type === ContactField_type.OPTIONS)
      return (
        <CustomPropertyOptionSelect
          value={value}
          handleChange={(newValue) => handleInputChange(nameKey, newValue)}
          options={
            customContactFields.find((field) => field.nameKey === nameKey)
              ?.typeOptions
          }
        />
      );
    else
      return (
        <CustomPropertyInput
          type={type}
          nameKey={nameKey}
          value={value}
          onChange={handleInputChange}
        />
      );
  };

  return (
    <ContactBox
      className={`fadein ${
        customProperty.type === ContactField_type.LONGTEXT
          ? 'contacts-col-12'
          : 'contacts-col-5'
      }`}
    >
      <Box display="flex" position="relative" minHeight="25px">
        <FieldName fontSize={fontSize.sm} flexGrow={isEditing ? 1 : 0}>
          {getPropertyName(customProperty.nameKey)}
        </FieldName>
        {isEditing ? (
          <>
            <StyledSaveCancelButton size="small" onClick={handleSave}>
              {isSaving ? <CircularProgress size={24} /> : <SaveIcon />}
            </StyledSaveCancelButton>
            <StyledSaveCancelButton size="small" onClick={handleCancel}>
              <CancelIcon />
            </StyledSaveCancelButton>
          </>
        ) : (
          <StyledIconButton
            className="edit-icon"
            size="small"
            onClick={toggleEdit}
          >
            <EditIcon />
          </StyledIconButton>
        )}
      </Box>

      <Box
        width={
          isEditing || customProperty.type === ContactField_type.LONGTEXT
            ? '100%'
            : '50%'
        }
        position="relative"
      >
        {isEditing ? (
          EditField()
        ) : (
          <FieldValue fontSize={mediaQuery.xl ? fontSize.md : fontSize.sm}>
            {customProperty.nameKey === 'rango-fecha'
              ? `${dayjs(property?.value[0]).format(userDateFormat)} - ${dayjs(
                  property?.value[1]
                ).format(userDateFormat)}`
              : customProperty.nameKey === 'rango-tiempo'
              ? `${dayjs(property?.value[0]).format(userTimeFormat)} - ${dayjs(
                  property?.value[1]
                ).format(userTimeFormat)}`
              : `${property?.value[0]} - ${property?.value[1]}` // Default format if not 'rango-fecha' or 'rango-tiempo'
              ? property?.value
              : '-'}
          </FieldValue>
        )}
      </Box>
    </ContactBox>
  );
};

export default ContactCustomFieldLabel;
