import { IContactAddress } from '@trii/types/dist/Contacts';
import { styled } from '@mui/system';
import { Box, Avatar, Modal, IconButton, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AvatarViewer } from 'features/Views/ContactView/components/HeaderContactViewer/components';
import { useSelector } from 'react-redux';
import { selectContactData } from 'ReduxToolkit/features/contactsSlice/contactsSlice';
import useSASAvatarURL from 'hooks/useSASAvatarURL';
import { Campaign, Star, StarBorder } from '@mui/icons-material';
import getImage from 'functions/getImage';
import { ModalSubscription } from './components';


type Props = {
  item: IContactAddress;
};

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.dropdownMenu,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  color: theme.palette.text.primary,
  width: '50%',
  paddingBottom: '3px',
  minHeight: '50px',
  position: 'relative',

}));
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(0.5),
}));


const LeftSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: theme.spacing(1),
  overflow: 'hidden',
}));
const RightSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  overflow: 'hidden',
}));

const ContactDetails = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(0.5),
  maxWidth: '-webkit-fill-available',
}));

const AvatarWrapper = styled(Avatar)(({ theme }) => ({
  width: 32,
  height: 32,
  cursor: 'pointer', // Indica que es clickeable
}));

const EllipsisText = styled(Box)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));
const NoteText = styled(EllipsisText)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.text.secondary,
  position: 'absolute',
  bottom: 0

}));
const PhoneText = styled(EllipsisText)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
}));
const ProfileNameText = styled(EllipsisText)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
}));



const ContactMethodItem = ({ item }: Props) => {
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = useState(false);
  const { icon } = getImage(item.channelType);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);
  const contactData = useSelector(selectContactData);
  const imageURL = item.profileUrl;
  const imageURLWithAccess = useSASAvatarURL(imageURL);

  const stringAvatar = (name: string) => {
    if (name) {
      const firstLetter = `${name.split(' ')[0][0]}`;
      return { children: `${firstLetter}` };
    }
    return { children: '' };
  };
  const [showModalSubs, setShowModalSubs] = useState(false);
  const [contactDataModal, setContactDataModal] = useState<IContactAddress>();

  const openModalSubs = (data: IContactAddress) => {
    setContactDataModal(data);
    setShowModalSubs(true);
  };
  const closeModalSubs = () => {
    setShowModalSubs(false);
  };
  // const handleSetFavorite = async (contactAddressId: string, value: boolean) => {
  //   const data: SetFavoriteAddressData = {
  //     contactId,
  //     contactAddressId,
  //     value,
  //   };

  //   const res = await dispatch(setFavoriteAddress(data));

  //   if (res.payload === 'OK') {
  //     switch (type) {
  //       case ChannelType.PHONE:
  //         const newPhones = contactInfo.phones.map((phone) => {
  //           if (phone.id === contactAddressId) {
  //             return { ...phone, isFavorite: value };
  //           }
  //           return phone;
  //         });

  //         setContactInfo({
  //           ...contactInfo,
  //           phones: newPhones,
  //         });
  //         break;

  //       case ChannelType.EMAIL:
  //         const newEmails = contactInfo.emails.map((email) => {
  //           if (email.id === contactAddressId) {
  //             return { ...email, isFavorite: value };
  //           }
  //           return email;
  //         });

  //         setContactInfo({
  //           ...contactInfo,
  //           emails: newEmails,
  //         });
  //         break;

  //       case ChannelType.WHATSAPP:
  //         const newWhatsapp = contactInfo.ims_whatsapp.map((whatsapp) => {
  //           if (whatsapp.id === contactAddressId) {
  //             return { ...whatsapp, isFavorite: value };
  //           }
  //           return whatsapp;
  //         });

  //         setContactInfo({
  //           ...contactInfo,
  //           ims_whatsapp: newWhatsapp,
  //         });
  //         break;

  //       case ChannelType.FACEBOOK:
  //         const newFacebook = contactInfo.ims_facebook.map((facebook) => {
  //           if (facebook.id === contactAddressId) {
  //             return { ...facebook, isFavorite: value };
  //           }
  //           return facebook;
  //         });

  //         setContactInfo({
  //           ...contactInfo,
  //           ims_facebook: newFacebook,
  //         });
  //         break;

  //       case ChannelType.INSTAGRAM:
  //         const newInstagram = contactInfo.ims_instagram.map((instagram) => {
  //           if (instagram.id === contactAddressId) {
  //             return { ...instagram, isFavorite: value };
  //           }
  //           return instagram;
  //         });

  //         setContactInfo({
  //           ...contactInfo,
  //           ims_instagram: newInstagram,
  //         });
  //         break;

  //       default:
  //         break;
  //     }
  //   }
  // };
  return (
    <>
      <StyledBox>
        <LeftSection>
          <StyledIconButton
            color="primary"
            size="small"
          >
            {icon}
          </StyledIconButton>
          <ContactDetails>
          <Tooltip title={item.address || ''} arrow>
              <PhoneText>{item.address}</PhoneText>
            </Tooltip>
            <Tooltip title={item.profileName || ''} arrow>
              <ProfileNameText sx={{
                marginTop: '-10px',
                marginLeft: '5px',
                fontSize: '10px'
              }}>{item.profileName}</ProfileNameText>
            </Tooltip>
            <Tooltip title={item.note || ''} arrow>
              <NoteText>{item.note}</NoteText>
            </Tooltip>
          </ContactDetails>
        </LeftSection>
          {/* <AvatarWrapper
            src={imageURLWithAccess}
            alt={item.profileName}
            onClick={handleOpenModal}
            style={{ cursor: 'pointer' }}
          >
            {item?.profileName?.charAt(0).toUpperCase()}
          </AvatarWrapper> */}
         <RightSection>
          
        {imageURL !== '' && (
          <AvatarWrapper
            src={imageURLWithAccess}
            alt={item.profileName}
            onClick={handleOpenModal}
            style={{ cursor: 'pointer' }}
          >
            {item?.profileName?.charAt(0).toUpperCase()}
          </AvatarWrapper>
        )}
          <AvatarWrapper
            src={imageURLWithAccess}
            alt={item.profileName}
            onClick={handleOpenModal}
            style={{ cursor: 'pointer' }}
          >
            {item?.profileName?.charAt(0).toUpperCase()}
          </AvatarWrapper>
        <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
                <Tooltip
                  title={t('conversations.contactInfo.suscriptions')}
                  placement="top"
                  arrow
                >
                  <IconButton
                    size="small"
                    onClick={() => openModalSubs(item)}
                    sx={{ marginTop: '-5px', marginRight: '5px', padding: 0 }}
                  >
                    <Campaign sx={{color: 'text.disabled'}} />
                  </IconButton>
                </Tooltip>
              <IconButton
                // onClick={() => handleSetFavorite(item.id, !item.isFavorite)}
                size="small"
                sx={{ marginTop: '-5px', padding: 0 }}
              >
                {item.isFavorite ? (
                  <Star
                    sx={{
                      color: '#fcac34',
                    }}
                  />
                ) : (
                  <StarBorder sx={{
                    color: '#fcac34',
                  }} />
                )}
              </IconButton>
            </Box>
        </RightSection> 
      </StyledBox>

      {/* Modal */}
      {imageURL && (
        <AvatarViewer
          open={isModalOpen}
          avatar={contactData.imageUrl !== '' ? imageURLWithAccess : ''}
          name={stringAvatar(item?.profileName).children}
          handleClose={handleCloseModal}
        />
      )}
      {/* Modal de suscripciones */}
      <ModalSubscription
        openModal={showModalSubs}
        onClose={closeModalSubs}
        icon={icon}
        contactAddress={contactDataModal}
      />


    </>
  );
};

export default ContactMethodItem;
