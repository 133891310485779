// Components/ui
import { SubsectionBodyContainer } from '../../../../layout';
import { Whatsapp } from './components';
// Types
import { NewAddress } from 'types/NewAddress';

type Props = {
  whatsapps: NewAddress[];
};

const Body = ({ whatsapps }: Props) => {
  return (
    <SubsectionBodyContainer variant="large">
      {whatsapps?.map((whatsapp) => (
        <Whatsapp key={whatsapp.id} data={whatsapp} />
      ))}
    </SubsectionBodyContainer>
  );
};

export default Body;
