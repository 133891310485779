// Components/ui
import { Box } from '@mui/material';
import { SubsectionHeaderWithButton } from '../../layout';
import { Body } from './components';
// Types
import { NewAddress } from 'types/NewAddress';

type Props = {
  RCSs: NewAddress[];
  handleAdd: () => void;
};
const RCSs = ({ RCSs, handleAdd }: Props) => {
  return (
    <Box sx={{ width: '100%' }}>
      <SubsectionHeaderWithButton handleAdd={handleAdd} title="RCS" />
      <Body RCSs={RCSs} />
    </Box>
  );
};

export default RCSs;
