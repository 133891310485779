import { useEffect, useState } from 'react';
// Components/ui
import {
  Box as MuiBox,
  IconButton,
  Modal as MuiModal,
  styled,
  CircularProgress,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { BusinessSelect } from '../../../../layout/ContactInfoLabel/components';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  selectContactData,
  setShowBusinessSearchModal,
  updateContact,
} from 'ReduxToolkit/features/contactsSlice/contactsSlice';
import { useSelector } from 'react-redux';
// Hooks
import useEditContact from 'features/Views/ContactEdit/hooks/useEditContact/useEditContact';

const StyledSaveCancelButton = styled(IconButton)({
  '& .MuiSvgIcon-root': {
    width: '0.8em',
    height: '0.8em',
  },
});

const StyledModal = styled(MuiModal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const StyledBox = styled(MuiBox)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: theme.palette.background.paper,
  boxShadow: theme.shadows[24],
  padding: theme.spacing(4),
  width: 400,
}));

const FlexBox = styled(MuiBox)({
  display: 'flex',
  gap: 16,
});

type BusinessSelectModalProps = {
  open: boolean;
};

const BusinessSelectModal = ({ open }: BusinessSelectModalProps) => {
  const dispatch = useAppDispatch();

  const [isSaving, setIsSaving] = useState(false);

  const contactData = useSelector(selectContactData);

  const editContact = useEditContact(contactData);

  const close = () => {
    editContact.action.resetSelectedBusiness();
    dispatch(setShowBusinessSearchModal(false));
  };

  const handleSave = async () => {
    setIsSaving(true);

    const { businessId, businessName } = editContact.field.selectedBusiness[0];

    await dispatch(updateContact({ businessId, businessName }));

    setIsSaving(false);
    close();
  };

  return (
    <StyledModal open={open} onClose={close}>
      <StyledBox>
        <FlexBox>
          <BusinessSelect contact={editContact} />
          <StyledSaveCancelButton size="small" onClick={handleSave}>
            {isSaving ? <CircularProgress size={24} /> : <SaveIcon />}
          </StyledSaveCancelButton>
        </FlexBox>
      </StyledBox>
    </StyledModal>
  );
};

export default BusinessSelectModal;
