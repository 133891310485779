import React, { useContext, useState } from 'react';
import { Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Modal, Typography, Button, CircularProgress } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useTranslation } from 'react-i18next';
import { NewAddress } from 'types/NewAddress';
import { CheckContactAddressData } from 'ReduxToolkit/features/contactsSlice/types/CheckContactAddressData';
import { resultContactAddressCheck } from '@trii/types/dist/ApiResponses';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { checkContactAddress } from 'ReduxToolkit/features/contactsSlice/contactsSlice';

type OptionsMenuProps = {
  onDelete: () => void;
  addressData: NewAddress;
  handleSave: () => Promise<void>;
  handleLinkAccountSave: () => Promise<void>;
  checked: boolean
};

const OptionsMenu = ({ onDelete, addressData, handleSave, handleLinkAccountSave, checked }: OptionsMenuProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { channelType, address } = addressData;
  const [verifying, setVerifying] = useState(false);
  const [verificationResult, setVerificationResult] = useState<resultContactAddressCheck | null>(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleDelete = () => {
    onDelete();
    handleClose();
  };

  const handleCheckWA = async () => {
    setVerifying(true);
    try {
      const checkContactAddressData: CheckContactAddressData = {
        address,
        type: 13,
      };

      const response = await dispatch(checkContactAddress(checkContactAddressData));
      const result = response.payload as resultContactAddressCheck;
      setVerificationResult(result);
      setModalOpen(true);
    } catch (error) {
      console.error('Error verifying WhatsApp account:', error);
    } finally {
      setVerifying(false);
    }
    handleClose();
  };

  const handleLinkAccount = async () => {
    await handleLinkAccountSave();
    setModalOpen(false); 
  };

  return (
    <Box>
      <IconButton
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <DeleteIcon color="error" />
          </ListItemIcon>
          <ListItemText>{t("global.delete")}</ListItemText>
        </MenuItem>
        {channelType === 5 && (
          <MenuItem onClick={handleCheckWA} disabled={!checked}>
            <ListItemIcon>
              {verifying ? (
                <CircularProgress size={24} />
              ) : (
                <WhatsAppIcon sx={{ color: "green" }} />
              )}
            </ListItemIcon>
            <ListItemText>{t("optionsMenu.verifyWspAccount")}</ListItemText>
          </MenuItem>
        )}
      </Menu>

      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
       
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            boxShadow: 24,
            p: 4,
            backgroundColor: (theme) => theme.palette.background.default,
          }}
        >
          {verificationResult?.success ? (
            <>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {t("optionsMenu.wspAccountFound")} {address}
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {t("optionsMenu.wantToLinkContact")}
              </Typography>
              <Box sx={{ marginTop: "10px" }}>
                <Button onClick={handleLinkAccount} color="primary">
                  {t("optionsMenu.yesLink")}
                </Button>
                <Button onClick={handleModalClose} color="secondary">
                  No
                </Button>
              </Box>
            </>
          ) : (
            <Box sx={{ position: "relative" }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {t("optionsMenu.noWspAccountFound")} {address}
              </Typography>
              <Button
                onClick={handleModalClose}
                color="secondary"
                sx={{ position: "absolute", bottom: -25, right: 0 }}
              >
                {t("global.cancel")}
              </Button>
            </Box>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default OptionsMenu;
