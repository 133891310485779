import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

type Props = {
  value: Dayjs | null;
  handleChange: (value: Dayjs | null) => void;
};

const CustomPropertyTimePicker = ({ value, handleChange }: Props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer
        components={["TimePicker"]}
        sx={{
          overflow: "visible",
          paddingTop: "0px",
        }}
      >
        <TimePicker
          value={value ? dayjs(value) : null}
          onChange={(newValue) => handleChange(newValue)}
          ampm={false}
          slotProps={{
            textField: {
              size: "small",
              sx: {
                width: "100%",
              },
              variant: 'standard'            
            },
            openPickerButton: {
              disableRipple: true
            }
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default CustomPropertyTimePicker;
