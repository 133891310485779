import { useContext } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Context
import { ContactBusinessTableContext } from 'components/ContactsBusinessTable/context/ContactBusinessTableContext';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Button, Divider, Typography } from '@mui/material';
// Slice
import {
  deleteContacts,
  getCheckedContactsIds,
  updateDeleteContacts,
} from 'ReduxToolkit/features/contactsSlice/contactsSlice';

const DeleteContact = () => {
  const { handleCloseModal } = useContext(ContactBusinessTableContext);
  const { t } = useTranslation();
  const contactIds = useSelector(getCheckedContactsIds);
  const dispatch = useAppDispatch();

  const handleDelete = async () => {
    if (contactIds?.length > 0) {
      dispatch(updateDeleteContacts(contactIds));
      dispatch(deleteContacts(contactIds));
      handleCloseModal();
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        height: '100%',
      }}
    >
      <Typography
        variant="h6"
        sx={{
          textAlign: 'center',
          margin: '1rem',
          color:(theme) => theme.palette.text.secondary

        }}
      >
        {t('contactsView.contactBusinessTable.deleteLabel')}
      </Typography>
      <Divider
        sx={{
          width: '100%',
        }}
      />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '.5rem',
        }}
      >
        <Button
          variant="outlined"
          color="error"
          size="small"
          sx={{
            borderRadius: '0.5rem',
            margin: '.5rem',
          }}
          onClick={handleCloseModal}
        >
          {t('global.cancel')}
        </Button>
        <Button
          variant="contained"
          color="error"
          size="small"
          sx={{
            borderRadius: '0.5rem',
            margin: '.5rem',
          }}
          onClick={handleDelete}
        >
          {t('global.confirm')}
        </Button>
      </Box>
    </Box>
  );
};

export default DeleteContact;
