import { useState, ChangeEvent } from 'react';

export type UseOptionsReturnType = {
  options: string[];
  textareaValue: string;
  handleOptionsChange: (event: ChangeEvent<HTMLInputElement>) => void;
  resetState: (options: string[]) => void;
  imposeState: (options: string[]) => void;
};

export const useOptionsInitDef = {
  options: [],
  textareaValue: '',
  handleOptionsChange: () => {},
  resetState: () => {},
  imposeState: () => {},
};

export const useOptions = (initialOptions: string[] = []): UseOptionsReturnType => {
  const [options, setOptions] = useState<string[]>(initialOptions);
  const [textareaValue, setTextareaValue] = useState<string>(
    initialOptions.join('\n')
  );

  const handleOptionsChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setTextareaValue(value); // Update the textarea value
    setOptions(value.split('\n')); // Update options array with each line as an element
  };

  const resetState = () => {
    setOptions([]);
    setTextareaValue('');
  };

  const imposeState = (options: string[]) => {
    setOptions(options);
    setTextareaValue(options.join('\n'));
  };

  return { options, textareaValue, handleOptionsChange, resetState, imposeState };
};
