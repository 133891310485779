import { useContext, useState } from 'react';
// Context
import { StyleContext } from 'style/styleProvider';
// Components/ui
import {
  Box,
  IconButton,
  Typography,
  TextField,
  Select,
  MenuItem,
  SelectChangeEvent,
  CircularProgress,
  styled,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
// Redux
import {
  setShowBusinessSearchModal,
  updateContact,
} from 'ReduxToolkit/features/contactsSlice/contactsSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
// Types
import { UserInfo } from '@trii/types/dist/Users';
import { DateSelect } from './components';
import UseEditContact from 'features/Views/ContactEdit/hooks/useEditContact/types/UseEditContact';
import dayjs from 'dayjs';

type Props = {
  title: string;
  value?: string | Date;
  isNotEditable?: boolean;
  inputType?: 'text' | 'select' | 'selectBusiness' | 'birthday';
  options?: UserInfo[];
  displayValue?: string;
  constructUpdateObject?: (value: string | Date) => object;
  contact?: UseEditContact;
};

const StyledIconButton = styled(IconButton)({
  opacity: 0,
  transition: 'opacity 0.3s ease-in-out',
  '& .MuiSvgIcon-root': {
    width: '0.8em',
    height: '0.8em',
  },
  marginLeft: '15px'

});
const StyledSaveCancelButton = styled(IconButton)({
  '& .MuiSvgIcon-root': {
    width: '0.8em',
    height: '0.8em',
  },
});

const ContactInfoLabel = ({
  title,
  value,
  isNotEditable,
  inputType = 'text',
  options = [],
  displayValue,
  constructUpdateObject,
  contact,
}: Props) => {
  const dispatch = useAppDispatch();
  const { fontSize } = useContext(StyleContext);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState(value);

  const handleEditClick = () => {
    if (inputType === 'selectBusiness') {
      dispatch(setShowBusinessSearchModal(true)); // Abre el modal si el tipo de input es "selectBusiness"
    } else {
      setIsEditing((prev) => !prev);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    setInputValue(e.target.value);
  };

  const handleCancel = () => {
    if (inputType === 'birthday' && contact) {
      contact.action.resetBirthDate();
    }
    setIsEditing(false);
  };

  const handleSave = async () => {
    if (constructUpdateObject) {
      setIsSaving(true);
      const updatedContactData =
        inputType === 'birthday'
          ? constructUpdateObject(contact.field.birthDate.toDate())
          : constructUpdateObject(inputValue);
      await dispatch(updateContact(updatedContactData));
      setIsSaving(false);
    }
    setIsEditing(false);
  };

  return (
    <Box
      className="fadein contacts-col-5"
      sx={{
        display: 'flex',
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark'
            ? '1e1e1e'
            : theme.palette.background.default,
        flexDirection: 'column',
        borderRadius: 1,
        minWidth: '45%',
        '&:hover .edit-icon': {
          opacity: 1,
        },
      }}
    >
      <Box display="flex" position="relative" minHeight={'25px'}>
        <Typography
          letterSpacing={-0.5}
          fontSize={fontSize.sm}
          fontWeight={'600'}
          alignSelf={'center'}
          flexGrow={isEditing ? 1 : 0} // Ocupa solo el espacio necesario cuando no está en modo de edición
          color={(theme) => theme.palette.text.primary}
        >
          {title}
        </Typography>

        {isNotEditable ? null : isEditing ? (
          <>
            <StyledSaveCancelButton size="small" onClick={handleSave}>
              {isSaving ? <CircularProgress size={24} /> : <SaveIcon />}
            </StyledSaveCancelButton>
            <StyledSaveCancelButton size="small" onClick={handleCancel}>
              <CancelIcon />
            </StyledSaveCancelButton>
          </>
        ) : (
          <StyledIconButton
            className="edit-icon"
            size="small"
            onClick={handleEditClick}
          >
            <EditIcon />
          </StyledIconButton>
        )}
      </Box>

      <Box width={isEditing ? '100%' : '50%'} position="relative">
        {isEditing && inputType === 'text' ? (
          <TextField
            value={inputValue}
            onChange={handleInputChange}
            variant="standard"
            size="small"
            sx={{ width: '100%' }}
          />
        ) : isEditing && inputType === 'select' ? (
          <Select
            value={inputValue}
            onChange={handleSelectChange}
            variant="standard"
            size="small"
            sx={{ width: '100%' }}
          >
            {options.map((option, index) => (
              <MenuItem key={index} value={option.id}>
                {option.name || option.email}
              </MenuItem>
            ))}
          </Select>
        ) : isEditing && inputType === 'birthday' ? (
          <DateSelect
            birthDate={contact.field.birthDate}
            setBirthDate={contact.action.setBirthDate}
          />
        ) : (
          <Typography
            fontSize={fontSize.sm}
            fontWeight={'400'}
            sx={{ padding: '2.5px 14px 8.5px 0px' }}
            color={(theme) => theme.palette.text.primary}
          >
            {displayValue || (typeof value === 'string' && value) || '-'}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ContactInfoLabel;
