// Components/ui
import { SubsectionBodyContainer } from '../../../../layout';
import { RCS } from './components';
// Types
import { NewAddress } from 'types/NewAddress';

type Props = {
  RCSs: NewAddress[];
};

const Body = ({ RCSs }: Props) => {
  return (
    <SubsectionBodyContainer variant="large">
      {RCSs?.map((data) => (
        <RCS key={data.id} data={data} />
      ))}
    </SubsectionBodyContainer>
  );
};

export default Body;
