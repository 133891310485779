import { Dispatch, useEffect, useState } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { TextField } from '@mui/material';
import { style } from './style';
import { StatusAdornment } from 'components';
// Types
import { ChangeEvent } from 'react';
import { ChannelType } from '@trii/types/dist/Common/Channels';
import { NewAddress } from 'types/NewAddress';
import { AdornmentStatus } from 'types/AdornmentStatus';
import { SetStateAction } from 'react';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import { CheckContactAddressData } from 'ReduxToolkit/features/contactsSlice/types/CheckContactAddressData';
import { checkContactAddress } from 'ReduxToolkit/features/contactsSlice/contactsSlice';
import { resultContactAddressCheck } from '@trii/types/dist/ApiResponses';

interface AddressInputProps {
  data: NewAddress;
  mode: 'edit' | 'create';
  onChange: (e: ChangeEvent, id: string, channelType?: ChannelType) => void;
  setWarnings: Dispatch<SetStateAction<string[]>>;
}

const AddressInput = ({ data, mode, onChange, setWarnings }: AddressInputProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { id, channelType, address } = data;
  const [adornmentStatus, setAdornmentStatus] = useState<AdornmentStatus>('idle');
  const [lastSearch, setLastSearch] = useState(address);
  const addressIsSaved = !data.hasOwnProperty('isSaved');

  useEffect(() => {
    let timer = setTimeout(async () => {
      if (address !== '' && address !== lastSearch) {
        setAdornmentStatus('loading');
        setLastSearch(address);

        const checkContactAddressData: CheckContactAddressData = {
          address,
          type: channelType,
        };
        const response = await dispatch(
          checkContactAddress(checkContactAddressData)
        );
        const { success, warnings } = response.payload as resultContactAddressCheck;

        setWarnings(warnings);

        success
          ? setAdornmentStatus('available')
          : setAdornmentStatus('unavailable');
      }
    }, 1700);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <TextField
        disabled={addressIsSaved}
        sx={style.addressField}
        size="small"
        label={
          channelType === ChannelType.WHATSAPP || channelType === ChannelType.RCS
            ? t('global.number')
            : t('global.email')
        }
        value={address}
        onChange={(e) => {
          mode === 'edit' ? onChange(e, id, channelType) : onChange(e, id);
        }}
        variant="outlined"
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        className="contacts-smallInput"
        InputProps={{
          endAdornment: (
            <>
              <StatusAdornment status={adornmentStatus} />
            </>
          ),
        }}
      />
      {}
    </>
  );
};

export default AddressInput;
