import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
// Translation
import { useTranslation } from 'react-i18next';
// Redux
import {
  selectBusinessData,
  selectBusinessStatusFetchBusinessById,
} from 'ReduxToolkit/features/businessSlice/selectors';
import { setAvoidReset } from 'ReduxToolkit/features/contactsFilterSlice/contactsFilterSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useSelector } from 'react-redux';
// Components/ui
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  styled,
  Theme,
  Tooltip,
  tooltipClasses,
  Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Spinner } from 'react-bootstrap';
import Labels from './components/Labels';
// Hooks
import useImage from 'hooks/useImage';

type TooltipProps = {
  className?: string;
  title: string;
  placement: 'bottom';
};
const FORMAT_DATE = 'DD/MM/YYYY HH:mm:ss';
const Header = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const businessData = useSelector(selectBusinessData);
  const businessFetchStatus = useSelector(selectBusinessStatusFetchBusinessById);

  const businessImage = useImage(businessData.imageUrl);

  const lastUpdateDate = moment(businessData.updatedAt).format(FORMAT_DATE);

  const openEditContact = useCallback(() => {
    navigate(`/a/contacts/business/${businessData.id}/edit`);
  }, [businessData]);

  const backToList = useCallback(() => {
    dispatch(setAvoidReset(true));
    navigate('/a/contacts/business');
  }, []);

  const TooltipLastUpdate = styled(
    ({ className, title, placement }: TooltipProps) => (
      <Tooltip classes={{ popper: className }} title={title} placement={placement}>
        <Typography variant="caption">{title}</Typography>
      </Tooltip>
    )
  )(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      top: -7,
    },
  }));

  // ACCIONES
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const [userName, setUserName] = useState('User Name');

  const handleNavigate = () => {
    navigate(`/a/contacts/duplicates?contactId=${businessData.id}`);
  };

  useEffect(() => {
    setUserName(businessData.name);
  }, [businessData.name]);

  function stringAvatar(name) {
    if (name !== undefined && name.length > 0) {
      const nameSplit = name.split(' ');
      let letras = '';
      for (let i = 0; i <= nameSplit.length - 1 && i <= 1; i++) {
        try {
          letras = letras + nameSplit[i][0];
        } catch (err) {}
      }
      return {
        children: letras,
      };
    } else {
      <Spinner
        animation="border"
        style={{
          marginLeft: 5,
          marginRight: 5,
          height: 54,
          width: 54,
          fontSize: '14px',
          color: 'lightgray',
        }}
      />;
    }
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
        borderBottom: '1px solid',
        borderColor: (theme: Theme) => theme.palette.divider,
        // backgroundColor: (theme: Theme) => theme.palette.background.default,
        color: (theme: Theme) => theme.palette.text.primary,
      }}
      height="auto"
      px={2}
      pl={1}
      py={1}
    >
      <Box width="100%">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <IconButton
              size="small"
              onClick={backToList}
              sx={{ color: (theme: Theme) => theme.palette.text.primary }}
            >
              <ArrowBackIcon />
            </IconButton>
            <IconButton size="small">
              {businessFetchStatus === 'loading' ? (
                <Skeleton
                  height={38}
                  width={38}
                  variant="circular"
                  sx={{ backgroundColor: '#f5f5f5' }}
                  animation="wave"
                />
              ) : (
                <Avatar
                  src={businessData.imageUrl !== '' ? businessImage.imageUrl : ''}
                  alt={businessData.name}
                  sx={{
                    cursor: 'pointer',
                    border: '1px solid #ced1d5',
                    bgcolor: (theme) => `${theme.palette.primary.main}50`,
                    color: (theme) => theme.palette.text.secondary,
                  }}
                />
              )}
            </IconButton>
            <Box maxWidth="40vw" ml={2}>
              {businessFetchStatus === 'succeeded' ? (
                <>
                <Tooltip title= {businessData.name}>

                  <Typography
                    variant="subtitle1"
                    mb={'-5px'}
                    sx={{
                      wordWrap: 'break-word',
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    className="ellipsified"
                  >
                    {businessData.name}
                  </Typography>
                </Tooltip>
                  <Box display="flex" sx={{ alignItems: 'baseline' }}>
                    <Labels labels={businessData.tags} />
                  </Box>
                </>
              ) : businessFetchStatus === 'loading' ? (
                <Skeleton
                  height={60}
                  width={100}
                  sx={{ backgroundColor: '#f5f5f5' }}
                  animation="wave"
                />
              ) : null}
            </Box>
          </Box>
          <Box display="flex" flexDirection={'column'} alignItems="flex-end">
            <Box>
              <Button
                sx={{ alignSelf: 'center', bgcolor: 'primary.main' }}
                variant="contained"
                size="small"
                onClick={openEditContact}
              >
                {t('global.edit')}
              </Button>
              <Button
                sx={{ alignSelf: 'center', ml: 1 }}
                endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                variant="outlined"
                size="small"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClickMenu}
              >
                {t('global.actions')}
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseMenu}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem>{t('contactsView.actions.clone')}</MenuItem>
                <MenuItem>{t('contactsView.actions.delete')}</MenuItem>
                <MenuItem disabled>
                  {t('contactsView.actions.printPreview')}
                </MenuItem>
                <MenuItem disabled onClick={handleNavigate}>
                  {t('contactsView.actions.findMergeDuplicates')}
                </MenuItem>
              </Menu>
            </Box>
            <Box display={'flex'} mt={1} sx={{ opacity: 0.7 }}>
              <Typography variant="caption" mr={1}>
                {t('contactDetails.lastUpdate')}:
              </Typography>
              <TooltipLastUpdate title={lastUpdateDate} placement="bottom" />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
