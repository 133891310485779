// Components/ui
import { Box, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ValueFieldProps {
  name: string;
  value: string;
  isDefault: boolean;
  onChangeHandler: (fieldKey: string, value) => void;
  nameKey: string;
}

const ValueField = ({
  onChangeHandler,
  name,
  value,
  isDefault,
  nameKey,
}: ValueFieldProps) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" width={'100%'}>
      <TextField
        fullWidth
        className="fadein"
        variant="outlined"
        label={isDefault ? t(`global.${name.toLowerCase()}`) : name}
        type="text"
        size="small"
        onChange={(e) => {
          onChangeHandler(nameKey, e.target.value);
        }}
        value={value}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Box>
  );
};

export default ValueField;
