import React, { useState } from 'react';
import { Contacts } from '@trii/types';
import { useTranslation } from 'react-i18next';
// components/ui
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { UseFieldType } from 'hooks/useField';
import { UseSelectType } from 'hooks/useSelect';
import { UseOptionsReturnType } from 'hooks/useOptions';

type Props = {
  getFieldType: (type: string) => string;
  isEditing: boolean;
  createField: UseFieldType;
  createSelect: UseSelectType;
  createOptionsTextArea: UseOptionsReturnType;
  editField: UseFieldType;
  editSelect: UseSelectType;
  editOptionsTextArea: UseOptionsReturnType;
};

const CreateFieldPanel = ({
  isEditing,
  getFieldType,
  createField,
  createSelect,
  createOptionsTextArea,
  editField,
  editSelect,
  editOptionsTextArea,
}: Props) => {
  const { t } = useTranslation();
  const types = Object.keys(Contacts.ContactField_type).filter(
    (x) => !(parseInt(x) >= 0)
  );

  const textFieldAttributes = isEditing
    ? editField.attributes
    : createField.attributes;
  const selectAttributes = isEditing
    ? editSelect.attributes
    : createSelect.attributes;
  const optionsTexArea = isEditing ? editOptionsTextArea : createOptionsTextArea;

  return (
    <Box
      display={'flex'}
      flexDirection="column"
      justifyContent="space-between"
      alignItems={'center'}
      p={2}
    >
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        label={t('settingsView.customFieldName')}
        sx={{
          marginBottom: '15px',
        }}
        {...textFieldAttributes}
      />
      <FormControl size="small" fullWidth sx={{ marginBottom: '15px' }}>
        <InputLabel>{t('settingsView.type')}</InputLabel>
        <Select
          label={t('settingsView.type')}
          disabled={isEditing ? true : false}
          {...selectAttributes}
          MenuProps={{
            PaperProps: {
              sx: {
                //@ts-ignore
                backgroundColor: (theme) => theme.palette.background.panel,
                backgroundImage:
                  'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
              },
            },
          }}
        >
          {types.map((value) => (
            <MenuItem key={value} value={value}>
              {getFieldType(value)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectAttributes.value === 'OPTIONS' && (
        <TextField
          variant="outlined"
          onChange={optionsTexArea.handleOptionsChange}
          value={optionsTexArea.textareaValue}
          size="small"
          fullWidth
          label={t('global.list')}
          multiline
          rows={12}
          sx={{
            marginBottom: '15px',
          }}
        />
      )}
    </Box>
  );
};

export default CreateFieldPanel;
